<template>

  <main>

    <section class="login-header">
      <div class="login-left">
      </div>
      <div class="login-center">
        <div class="login-center--header"></div>
        <div class="form-signin shadow-sm">
          <h1 class="text-center mb-3">Hamarosan...</h1>
          <p class="text-center">A regisztráció<br><span class="fw-bold">2023. augusztus 10. (szerda) 8:00-tól</span><br>elérhető.</p>
          <p class="text-center">Kérjük, gyere vissza később.</p>
        </div>
      </div>
      <div class="login-right">
      </div>
    </section>

    <section class="login-footer">
    </section>

  </main>

</template>


<script>
export default {
  name: 'ComingSoon',
  props: ['initData'],
  data() {
    let serverDateTime = this.initData.user.serverDateTime

    let eventData = this.initData.event
    let comingSoonData = eventData.comingSoon
    if(serverDateTime>comingSoonData.start){
      this.$router.push({name: 'login'})
    }
    return {

    }
  }
}
</script>


<style>

</style>