<template>

  <main>

    <section class="container pb-5">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">

        <h1 class="container text-center mb-3 pt-3">{{guestListContents.title}}</h1>
        <p class="text-center">{{guestListContents.txt.replace('{guestNumber}',maxGuestNumber)}}</p>

        <template v-if="guests.length>0" v-for="guest in guests">
          <div class="card-guest d-flex justify-content-between align-items-center my-2 p-2">
            <div class="card-guest__name">
              <i class="bi bi-person-circle me-1"></i>
              {{guest.lastName}} {{guest.firstName}}
            </div>
            <div class="card-guest__btn d-flex">
              <router-link :to="{name:'userMyGuest',params: {guestId : guest.idNr}}">
                <button class="btn btn-info btn-sm bi bi-arrow-right d-block"></button>
              </router-link>
            </div>
          </div>
        </template>

        <router-link :to="{name:'userNewGuest'}">
          <button type="submit" class="btn btn-primary form-control mt-4">{{guestListContents.btn}}</button>
        </router-link>

      </div>
    </section>

  </main>

</template>


<script>
export default {
  name: 'UserGuests',
  props: ['initData'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }
    let maxGuestNumber = this.initData.event.maxGuestNumber
    let generalContents = this.initData.lang.general
    let guestListContents = this.initData.lang.user.guestList
    let guests = this.initData.user.guests
    maxGuestNumber = maxGuestNumber - guests.length

    return {
      generalContents,
      guestListContents,
      maxGuestNumber,
      guests
    }
  }
}
</script>


<style>

</style>