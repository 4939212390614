import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login'

// import CodeRequest from '../views/Code/Request'
// import CodeSent from '../views/Code/Sent'
// import CodeVerify from '../views/Code/Verify'
// import CodeApply from '../views/Code/Apply'
const CodeRequest = () => import(/* webpackChunkName: "codeFunctions" */'../views/Code/Request')
const CodeSent = () => import(/* webpackChunkName: "codeFunctions" */'../views/Code/Sent')
const CodeVerify = () => import(/* webpackChunkName: "codeFunctions" */'../views/Code/Verify')
const CodeApply = () => import(/* webpackChunkName: "codeFunctions" */'../views/Code/Apply')

import ComingSoon from '../views/ComingSoon'
import Agenda from '../views/Agenda'
import ProgramDetails from '../views/Program/Details'

import Info from '../views/Info'
import Info_2 from '../views/Info_2'
import Map from '../views/Map'

import UserAccount from '../views/User/Account.vue'
import UserRegistration from '../views/User/Registration.vue'
import UserGuests from '../views/User/GuestList.vue'
import UserNewGuest from '../views/User/NewGuest.vue'
import UserMyGuest from '../views/User/GuestProfile.vue'
import UserQrCodes from '../views/User/QrCodes.vue'
import UserTickets from '../views/User/Tickets.vue'
import UserCustomQrCode from '../views/User/CustomQrCode.vue'
import UserParticipation from '../views/User/Participation.vue'
import UserParticipate from '../views/User/Participate.vue'
import UserGuestParticipates from '../views/User/GuestParticipates.vue'
import UserNewTeam from '../views/User/NewTeam.vue'
import UserModifyTeam from '../views/User/ModifyTeam.vue'
import UserVoluntaryActs from '../views/User/VoluntaryActs.vue'

//import ProviderCodeReader from '../views/Provider/CodeReader'
//import ProviderCodeCheckResponse from '../views/Provider/CodeCheckResponse'
//import ProviderCodeActivateResponse from '../views/Provider/CodeActivateResponse'
const ProviderCodeReader = () => import(/* webpackChunkName: "provider" */'../views/Provider/CodeReader')
const ProviderCodeCheckResponse = () => import(/* webpackChunkName: "provider" */'../views/Provider/CodeCheckResponse')
const ProviderCodeActivateResponse = () => import(/* webpackChunkName: "provider" */'../views/Provider/CodeActivateResponse')

// import RegistratorCodeReader from '../views/Registrator/CodeReader'
// import RegistratorCodeCheckResponse from '../views/Registrator/CodeCheckResponse'
// import RegistratorCodeActivateResponse from '../views/Registrator/CodeActivateResponse'
// import RegistratorNewGuest from '../views/Registrator/NewGuest'
const RegistratorCodeReader = () => import(/* webpackChunkName: "registrator" */'../views/Registrator/CodeReader')
const RegistratorCodeCheckResponse = () => import(/* webpackChunkName: "registrator" */'../views/Registrator/CodeCheckResponse')
const RegistratorCodeActivateResponse = () => import(/* webpackChunkName: "registrator" */'../views/Registrator/CodeActivateResponse')
const RegistratorNewGuest = () => import(/* webpackChunkName: "registrator" */'../views/Registrator/NewGuest')

//import SuperVisorCodeReader from '../views/Supervisor/CodeReader'
//import SuperVisorCodeCheckResponse from '../views/Supervisor/CodeCheckResponse'
const SuperVisorCodeReader = () => import(/* webpackChunkName: "supervisor" */'../views/Supervisor/CodeReader')
const SuperVisorCodeCheckResponse = () => import(/* webpackChunkName: "supervisor" */'../views/Supervisor/CodeCheckResponse')

//import Admin from '../views/Admin/Admin'
// import AdminEventDetails from '../views/Admin/Event/Details'
// import AdminEventStat from '../views/Admin/Event/Stat'
// import AdminProgramList from '../views/Admin/Program/List'
// import AdminProgramDetails from '../views/Admin/Program/Details'
// import AdminProgramNew from '../views/Admin/Program/NewProgram'
// import AdminProviderList from '../views/Admin/Provider/List'
// import AdminProviderDetails from '../views/Admin/Provider/Details'
// import AdminProviderNew from '../views/Admin/Provider/NewProvider'
// import AdminLocationList from '../views/Admin/Location/List'
// import AdminLocationDetails from '../views/Admin/Location/Details'
// import AdminLocationNew from '../views/Admin/Location/NewLocation'
// import AdminTeamList from '../views/Admin/Team/List'
// import AdminTeamNew from '../views/Admin/Team/NewTeam'
// import AdminTicketList from '../views/Admin/Ticket/List'
// import AdminTicketNew from '../views/Admin/Ticket/NewTicket'
// import AdminTicketAssignPerson from '../views/Admin/Ticket/AssignPerson'
// import AdminTicketAssignGroup from '../views/Admin/Ticket/AssignGroup'

const Admin = () => import(/* webpackChunkName: "admin" */'../views/Admin/Admin')
const AdminEventDetails = () => import(/* webpackChunkName: "admin" */'../views/Admin/Event/Details')
const AdminEventStat = () => import(/* webpackChunkName: "admin" */'../views/Admin/Event/Stat')
const AdminProgramList = () => import(/* webpackChunkName: "admin" */'../views/Admin/Program/List')
const AdminProgramDetails = () => import(/* webpackChunkName: "admin" */'../views/Admin/Program/Details')
const AdminProgramNew = () => import(/* webpackChunkName: "admin" */'../views/Admin/Program/NewProgram')
const AdminProviderList = () => import(/* webpackChunkName: "admin" */'../views/Admin/Provider/List')
const AdminProviderDetails = () => import(/* webpackChunkName: "admin" */'../views/Admin/Provider/Details')
const AdminProviderNew = () => import(/* webpackChunkName: "admin" */'../views/Admin/Provider/NewProvider')
const AdminLocationList = () => import(/* webpackChunkName: "admin" */'../views/Admin/Location/List')
const AdminLocationDetails = () => import(/* webpackChunkName: "admin" */'../views/Admin/Location/Details')
const AdminLocationNew = () => import(/* webpackChunkName: "admin" */'../views/Admin/Location/NewLocation')
const AdminTeamList = () => import(/* webpackChunkName: "admin" */'../views/Admin/Team/List')
const AdminTeamNew = () => import(/* webpackChunkName: "admin" */'../views/Admin/Team/NewTeam')
const AdminTicketList = () => import(/* webpackChunkName: "admin" */'../views/Admin/Ticket/List')
const AdminTicketNew = () => import(/* webpackChunkName: "admin" */'../views/Admin/Ticket/NewTicket')
const AdminTicketAssignPerson = () => import(/* webpackChunkName: "admin" */'../views/Admin/Ticket/AssignPerson')
const AdminTicketAssignGroup = () => import(/* webpackChunkName: "admin" */'../views/Admin/Ticket/AssignGroup')



const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },{
    path: '/coming-soon',
    name: 'comingSoon',
    component: ComingSoon
  },{
    path: '/agenda',
    name: 'agenda',
    component: Agenda
  },{
    path: '/program-details/:programId',
    name: 'programDetails',
    component: ProgramDetails,
    props: true
  },{
    path: '/info',
    name: 'info',
    component: Info
  },{
    path: '/koltsegterites',
    name: 'info_2',
    component: Info_2
  },{
    path: '/map',
    name: 'map',
    component: Map
  },{
    path: '/code-verify',
    name: 'codeVerify',
    component: CodeVerify
  },{
    path: '/code-request',
    name: 'codeRequest',
    component: CodeRequest
  },{
    path: '/code-sent',
    name: 'codeSent',
    component: CodeSent
  },{
    path: '/code-apply/:securityHash',
    name: 'codeApply',
    component: CodeApply,
    props: true
  },{
    path: '/me',
    name: 'userAccount',
    component: UserAccount,
  },{
    path: '/me/personal-data',
    name: 'userRegistration',
    component: UserRegistration,
  },
  {
    path: '/me/my-guests',
    name: 'userGuests',
    component: UserGuests,
  },
  {
    path: '/me/new-guest',
    name: 'userNewGuest',
    component: UserNewGuest,
  },
  {
    path: '/me/my-guest/:guestId',
    name: 'userMyGuest',
    component: UserMyGuest,
    props: true
  },
  {
    path: '/me/qr-codes',
    name: 'userQrCodes',
    component: UserQrCodes,
  },
  {
    path: '/me/tickets',
    name: 'userTickets',
    component: UserTickets,
  },
  {
    path: '/me/custom-qr-code',
    name: 'userCustomQrCode',
    component: UserCustomQrCode,
  },
  {
    path: '/me/participation',
    name: 'userParticipation',
    component: UserParticipation,
  },
  {
    path: '/me/participate/:guestId',
    name: 'userParticipate',
    component: UserParticipate,
    props: true
  },
  {
    path: '/me/guest-participates',
    name: 'userGuestParticipates',
    component: UserGuestParticipates,
  },
  {
    path: '/me/new-team',
    name: 'userNewTeam',
    component: UserNewTeam,
  },
  {
    path: '/me/modify-team/:entryId',
    name: 'userModifyTeam',
    component: UserModifyTeam,
    props: true
  },
  {
    path: '/me/voluntary',
    name: 'userVoluntary',
    component: UserVoluntaryActs,
  },
  {
    path: '/provider',
    name: 'provider',
    component: ProviderCodeReader,
  },
  {
    path: '/provider/code-check-response',
    name: 'providerCodeCheckResponse',
    component: ProviderCodeCheckResponse
  },
  {
    path: '/provider/code-activate-response',
    name: 'providerCodeActivateResponse',
    component: ProviderCodeActivateResponse
  },
  {
    path: '/registrator',
    name: 'registrator',
    component: RegistratorCodeReader,
  },
  {
    path: '/registrator/code-check-response',
    name: 'registratorCodeCheckResponse',
    component: RegistratorCodeCheckResponse
  },
  {
    path: '/registrator/code-activate-response',
    name: 'registratorCodeActivateResponse',
    component: RegistratorCodeActivateResponse
  },
  {
    path: '/registrator/new-guest',
    name: 'registratorNewGuest',
    component: RegistratorNewGuest
  },
  {
    path: '/supervisor',
    name: 'supervisor',
    component: SuperVisorCodeReader,
  },
  {
    path: '/supervisor/code-check-response',
    name: 'supervisorCodeCheckResponse',
    component: SuperVisorCodeCheckResponse
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    children:[
      {
        path: 'event-details',
        name: 'adminEventDetails',
        component: AdminEventDetails
      },{
        path: 'event-stat',
        name: 'adminEventStat',
        component: AdminEventStat
      }
      ,{
        path: 'program-list',
        name: 'adminProgramList',
        component: AdminProgramList
      },{
        path: 'program-details/:serviceId',
        name: 'adminProgramDetails',
        component: AdminProgramDetails,
        props: true
      },{
        path: 'new-program',
        name: 'adminNewProgram',
        component: AdminProgramNew
      },{
        path: 'provider-list',
        name: 'adminProviderList',
        component: AdminProviderList
      },{
        path: 'provider-details/:providerId',
        name: 'adminProviderDetails',
        component: AdminProviderDetails,
        props: true
      },{
        path: 'new-provider',
        name: 'adminNewProvider',
        component: AdminProviderNew
      },{
        path: 'location-list',
        name: 'adminLocationList',
        component: AdminLocationList
      },{
        path: 'location-details/:locationId',
        name: 'adminLocationDetails',
        component: AdminLocationDetails,
        props: true
      },{
        path: 'new-location',
        name: 'adminNewLocation',
        component: AdminLocationNew
      },{
        path: 'team-list',
        name: 'adminTeamList',
        component: AdminTeamList
      },{
        path: 'new-team',
        name: 'adminNewTeam',
        component: AdminTeamNew
      },{
        path: 'tickets',
        name: 'adminTickets',
        component: AdminTicketList
      },{
        path: 'new-ticket',
        name: 'adminNewTicket',
        component: AdminTicketNew
      },{
        path: 'assign-tickets-to-user',
        name: 'adminAssignTicketsToUser',
        component: AdminTicketAssignPerson
      },{
        path: 'assign-tickets-to-group',
        name: 'adminAssignTicketsToGroup',
        component: AdminTicketAssignGroup
      }
    ]
  }
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  history: createWebHistory('/'),
  routes
})

export default router
