<template>

  <main>

    <section class="container pt-4 pb-5">
      <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">

        <div class="d-flex justify-content-center align-items-center bg-info mb-2">
          <div v-html="guest.qrCode" class="qr-code"></div>
        </div>

        <h1 class="h4 mt-4 mb-3 lh-1">{{guest.lastName}} {{guest.firstName}}</h1>

        <table class="table table-borderless">
          <tbody>
            <tr>
              <th scope="row">Azonosító:</th>
              <td>{{eventId}}-{{guest.idNr}}</td>
            </tr>
            <tr>
              <th scope="row">Született:</th>
              <td>{{guest.birthDate}}</td>
            </tr>
            <tr>
              <th scope="row">Nem:</th>
              <td v-if="guest.gender===1">Nő</td>
              <td v-else>Férfi</td>
            </tr>
            <!--
            <tr>
              <th scope="row">Cég:</th>
              <td>-</td>
            </tr>
            <tr>
              <th scope="row">Divízió:</th>
              <td>-</td>
            </tr>
            -->
          </tbody>
        </table>

        <router-link :to="{name:'userGuests'}">
          <button class="btn btn-primary form-control">Mégsem tud résztvenni</button>
        </router-link>

        <router-link :to="{name:'userGuests'}">
          <button class="btn btn-outline-primary form-control mt-4"><i class="bi bi-arrow-left me-2"></i>{{generalContents.backBtn}}</button>
        </router-link>

      </div>
    </section>

  </main>

</template>


<script>
export default {
  name: 'UserMyGuest',
  props: ['initData','guestId'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }
    let generalContents = this.initData.lang.general
    let myGuestContents = this.initData.lang.user.myGuest
    let guests = this.initData.user.guests

    let guest = guests.find(g => g.idNr === this.guestId);
    if(guest===undefined){
      this.$router.push({name: 'userGuests'})
    }
    let eventId = this.initData.event.id

    return {
      eventId,
      generalContents,
      myGuestContents,
      guest
    }
  }
}
</script>


<style>

</style>