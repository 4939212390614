<template>
  <main>
    <section class="login-header">
      <div class="login-left">
      </div>
      <div class="login-center">
        <div class="login-center--header"></div>
        <div class="form-signin shadow-sm">
          <div class="login-not-started" v-if="loginStartDateTime>=serverDateTime">
            <p>Idén is megrendezésre kerül.<br><br>TESCO Csapatnap<br>2024.09.07.<br><br>Csapassuk együtt!<br>További információk hamarosan.</p>
          </div>
          <div v-show="loginStartDateTime<serverDateTime">
            <h1 class="text-center mb-3">Belépés</h1>
            <form @submit.prevent="handleSubmit">
              <div class="mb-3" v-if="emailRequired">
                <label for="emailInput" class="form-label">Céges email címed*</label>
                <input type="text" id="emailInput" class="form-control"
                       :class="emailErrorText ? 'is-invalid' : ''" v-model="email" maxlength="100" aria-describedby="emailHelp" autofocus>
                <div v-if="emailErrorText" class="small text-danger">
                  {{ emailErrorText }}
                </div>
                <div v-if="enabledEmails!==null" id="emailHelp" class="form-text">Kérjük, a céges email címedet add meg!</div>
              </div>
              <div class="mb-3 text-center" v-if="identityNumberRequired">
                <label for="identityNumberInput" class="form-label mb-0 fw-bold">Törzsszám / Azonosító</label>
                <input type="text" id="identityNumberInput" class="form-control text-center" style="max-width: 12em; margin: 0 auto;"
                       :class="identityNumberErrorText ? 'is-invalid' : ''" v-model="identityNumber" maxlength="100" aria-describedby="identityNumberHelp" autofocus>
                <div v-if="identityNumberErrorText" class="small text-danger">
                  {{ identityNumberErrorText }}
                </div>
              </div>
              <div class="mb-3 text-center" v-if="birthYearRequired">
                <label for="birthYearInput" class="form-label mb-0 fw-bold">Születési évszám</label>
                <input type="text" id="birthYearInput" class="form-control text-center" style="max-width: 7em; margin: 0 auto;"
                       :class="birthYearErrorText ? 'is-invalid' : ''" v-model="birthYear" maxlength="4" aria-describedby="birthYearHelp">
                <div v-if="birthYearErrorText" class="small text-danger">
                  {{ birthYearErrorText }}
                </div>
              </div>
              <div class="mb-4 form-check" style="padding-left: 3em; padding-right: 0.5em;">
                <input type="checkbox" class="form-check-input h3" style="margin-top: 0.1em; margin-left: -1.5em;"
                       :class="policyErrorText ? 'is-invalid' : ''" id="policyCheck" v-model="policy">
                <label class="form-check-label small" for="policyCheck">Elfogadom az <a href="/data/1/adatkezelesi-tajekoztato-tesco-csapatnap-2024.pdf" target="_blank">Adatkezelési Tájékoztató</a>t és a <a href="/data/1/hasznalati-feltetelek-tesco-csapatnap-2024.pdf" target="_blank">Használati Feltételek</a>et.</label>
                <div v-if="policyErrorText" class="small text-danger">
                  {{ policyErrorText }}
                </div>
              </div>
              <button type="submit" class="btn btn-primary form-control">Belépek</button>
            </form>
          </div>

        </div>
      </div>
      <div class="login-right">
      </div>
    </section>

    <section class="login-footer">
    </section>

  </main>

</template>


<script>
import {Response} from '@/classes/response.js'
import {Validate} from '@/classes/validate.js'

export default {
  name: 'Login',
  props: ['initData'],
  data() {
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===true){
      this.$router.push({name: 'agenda'})
    }


    let generalContents = this.initData.lang.general
    let loginFormContents = this.initData.lang.user.login
    let authSetup = this.initData.event.authSetup
    let emailRequired = authSetup.emailRequired
    let identityNumberRequired = authSetup.identityNumberRequired
    let birthYearRequired = authSetup.birthYearRequired
    let enabledEmails = authSetup.enabledEmails
    let externalAuth = authSetup.externalAuth
    let loginStartDateTime = this.initData.event.registrationSetup.startDateTime
    return {
      generalContents,
      loginFormContents,
      enabledEmails,
      email : null,
      identityNumber : null,
      identityNumberErrorText : null,
      birthYear : null,
      birthYearErrorText : null,
      emailErrorText : null,
      policy : false,
      policyErrorText : null,
      emailRequired,
      identityNumberRequired,
      birthYearRequired,
      externalAuth,
      loginStartDateTime,
      serverDateTime: this.initData.user.serverDateTime
    }
  },methods:{
    handleSubmit() {
      let validate = new Validate()

      //EMAIL
      if(this.emailRequired){
        this.emailErrorText = validate.emptyField(this.email, this.loginFormContents.errors.emailRequired)
        if(this.emailErrorText===null){
          this.emailErrorText = validate.email(this.email, this.loginFormContents.errors.emailWrongFormat)
        }
        if(this.enabledEmails!==null && this.emailErrorText===null){
          this.emailErrorText = validate.companyEmail(this.email, this.enabledEmails, this.loginFormContents.errors.needCompanyEmail)
        }
      }
      //IDENTITY NUMBER
      if(this.identityNumberRequired){
        this.identityNumberErrorText = validate.validIdentityNumber(this.identityNumber, this.loginFormContents.errors.identityNumberRequired)
      }
      //birthYear
      if(this.birthYearRequired){
        this.birthYearErrorText = validate.validBirthYear(this.birthYear, this.loginFormContents.errors.birthYearRequired)
      }

      this.policyErrorText = validate.checkboxChecked(this.policy,this.loginFormContents.errors.policyRequired)

      if (this.emailErrorText || this.identityNumberErrorText || this.birthYearErrorText || this.policyErrorText) {
        return false;
      }

      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('identityNumber', this.identityNumber)
      formData.append('birthYear', this.birthYear)
      formData.append('policy', this.policy)

      this.initData.loader = true
      this.$axios.post('/v1/user/login',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.initData.user.givedAuth = true
              this.initData.user.firstLogin = responseData.data.firstLogin
              let redirect = responseData.data.redirect
              if(this.externalAuth===true){
                if(redirect==='userRegistration'){
                  this.initData.user.registration = true
                }else{
                  this.initData.user.loggedIn = true
                  this.initData.programs = responseData.data.programs
                  this.initData.voluntaryActs = responseData.data.voluntaryActs
                  this.initData.info = responseData.data.info
                  this.initData.info_2 = responseData.data.info_2
                  this.initData.user.qrCode = responseData.data.qrCode
                  this.initData.user.idNr = responseData.data.idNr
                  this.initData.user.division = responseData.data.division
                  this.initData.user.eventApply = responseData.data.eventApply
                  this.initData.user.firstName = responseData.data.firstName
                  this.initData.user.lastName = responseData.data.lastName
                  this.initData.user.permission = responseData.data.permission
                  this.initData.user.birthDate = responseData.data.birthDate
                  this.initData.user.telephone = responseData.data.telephone
                  this.initData.user.gender = responseData.data.gender
                  this.initData.user.guests = responseData.data.guests
                  this.initData.user.customSelect_1 = responseData.data.customSelect_1
                }
              }
              this.$router.push({name: redirect})
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });

    }
  }
}
</script>


<style>
.login-not-started{
  white-space: pre;
  display: block;
  margin: 60px 0;
  font-weight: bold;
  text-align: center;
}
</style>