<template>

  <main>

    <section class="container py-3">

      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-2">
          <h1 v-if="serverDateTime<mapDisplay" class="h4 text-center mb-3">Térkép</h1>
          <p v-if="serverDateTime<mapDisplay" class="text-danger text-center fw-bold">A rendezvény időtartama alatt ezen a felületen egy térképén látod majd, hogy a programokat a Platán Strand melyik részén találod meg.</p>
        </div>
      </div>

      <div v-if="serverDateTime>mapDisplay">
        <div style="width: 100%">
          <iframe width="100%" height="600"
            frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0"
            src="https://www.google.com/maps/d/u/2/embed?mid=1l9DMAPVWDmriozGTB3_gfj5-R483YX4&ehbc=2E312F&amp;t=h&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
          </iframe>
        </div>
      </div>

    </section>

    <section class="container pb-5">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">

          <router-link :to="{name:'agenda'}">
            <button type="submit" class="btn btn-outline-primary form-control mt-4"><i class="bi bi-arrow-left me-2"></i>{{ generalContents.backBtn }}</button>
          </router-link>

        </div>
      </div>
    </section>

  </main>

</template>


<script>
export default {
  name: 'Map',
  props: ['initData'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    let permission = this.initData.user.permission
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }
    let serverDateTime = this.initData.user.serverDateTime
    let generalContents = this.initData.lang.general
    let eventData = this.initData.event
    let mapDisplay = eventData.pageDisplay.map
    if(serverDateTime<mapDisplay && permission!==90){
      this.$router.push({name: 'info'})
    }

    return {
      generalContents,
      mapDisplay,
      serverDateTime
    }
  }
}
</script>


<style>

</style>