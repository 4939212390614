<template>

  <main>

    <section class="container pt-3 pb-5">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="d-flex justify-content-center align-items-center mb-3">
            <!-- <div>Kép</div>-->
          <picture v-if="imgPath">
  <!--      <source srcset="sample-image-desktop.jpg" media="(min-width: 768px)" type="image/svg+xml">-->
  <!--      <img src="sample-image-mobile.jpg" class="img-fluid img-thumbnail" alt="...">-->
            <img :src="imgPath" class="photo">
          </picture>
        </div>

        <div class="text-primary">{{program.date}} {{program.startTime}}-{{program.endTime}} / {{program.locationName}}</div>
        <h1 class="h5" v-html="program.name"></h1>

        <div v-html="program.description"></div>
<!--        <div v-if="program.preRegistration==='0'">-->
<!--          <div class="btn btn-warning form-control mt-3">-->
<!--            A program nincs előzetes jelentkezéshez kötve-->
<!--          </div>-->
<!--        </div>-->
        <button class="btn btn-secondary form-control mt-3 mb-2" v-if="program.preRegistration==='1' && serverDateTime<program.regStartTime">
          Jelentkezés indul: {{program.regStartTimeDisplay}}
        </button>
        <router-link v-if="program.preRegistration==='1' && serverDateTime>program.regStartTime && serverDateTime<program.regEndTime" :to="{name:'userParticipation'}">
          <button class="btn btn-primary form-control mt-3 mb-2">Jelentkezés / Nevezés</button>
        </router-link>
        <button class="btn btn-secondary form-control mt-3" v-if="program.preRegistration==='1' && serverDateTime>program.regEndTime">
          Jelentkezést lezártuk
        </button>
        <div v-if="program.preRegistration==='1' && serverDateTime<program.regEndTime" class="text-center">
          Jelentkezést zárjuk: {{program.regEndTimeDisplay}}
        </div>
        <div class="d-flex">
          <router-link :to="{name:'agenda'}" class="d-block w-100">
            <button class="btn btn-outline-primary form-control mt-5"><i class="bi bi-arrow-left me-2"></i>Vissza</button>
          </router-link>
          <a v-if="program.mapUrl && serverDateTime>mapDisplay" :href="program.mapUrl" target="_blank" class="d-block w-100 ms-2">
            <button class="btn btn-outline-primary form-control mt-3"><i class="bi bi-pin-map me-2"></i>Térkép</button>
          </a>
        </div>
      </div>
    </section>

  </main>

</template>


<script>
  export default {
    name: 'ProgramDetails',
    props: ['initData','programId'],
    data(){
      let loggedIn = this.initData.user.loggedIn
      if(loggedIn===false){
        this.$router.push({name: 'login'})
      }
      let eventData = this.initData.event
      let mapSetup = eventData.mapSetup
      let mapDisplay = eventData.pageDisplay.map
      let serverDateTime = this.initData.user.serverDateTime
      let generalContents = this.initData.lang.general

      let programsData = this.initData.programs.data

      let program = programsData.find(p => p.idNr === this.programId);
      if(program===undefined){
        this.$router.push({name: 'agenda'})
      }

      let eventId = this.initData.event.id

      let prefix = '/app'
      if(window.location.hostname=== 'localhost'){
        prefix = ''
      }
      let imgPath = prefix+'/img/events/'+eventId+'/programs/'+program.idNr+'.jpg'

      let http = new XMLHttpRequest()
      http.open('HEAD', imgPath, false)
      http.send()
      if(http.status===404){
        imgPath = false
      }

      return {
        mapSetup,
        mapDisplay,
        serverDateTime,
        generalContents,
        program,
        imgPath
      }
    }
  }
</script>


<style scoped>

</style>