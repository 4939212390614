<template>

  <main>

    <section class="container pb-5">
      <div class="row">

        <div class="col-12 col-md-6 offset-md-3">

          <h1 class="container text-center mb-2 pt-3">{{participationContents.title}}</h1>

          <div class="text-center text-danger fw-bold" v-if="serverDateTime<programs.firstRegistrationDateTime">
            A nevezés még nem indult el.<br>
            Nevezés indulása: {{programs.firstRegistrationDateTimeDisplay}}
          </div>

          <div v-else>

            <div class="text-center mb-5" v-html="participationContents.txt"></div>

            <h2 class="text-center">Egyéni nevezés</h2>

            <div class="card-participation mb-3 p-2">

              <div class="d-flex justify-content-between align-items-center">
                <div class="card-participation__name">
                  <h2 class="h5 text-success mb-0">
                    <i class="bi bi-person-circle me-2"></i>{{ lastName }} {{ firstName }}
                  </h2>
                </div>
                <div>
                  <router-link :to="{name:'userParticipate',params: {guestId : idNr}}">
                    <button class="btn btn-primary btn-sm">Nevezés <i class="bi bi-arrow-right-short"></i></button>
                  </router-link>
                </div>
              </div>

              <div>
                <div v-if="entries.filter(function (el) {return el.idNr===idNr && el.teamLeader==='1'}).length>0" class="mt-3">
                  <h3 class="h6 fw-bold text-decoration-underline mb-2">
                    Csapatkapitányként beneveztél:
                  </h3>
                  <ul class="mb-3">
                    <li v-for="entry in entries.filter(function (el) {return el.idNr===idNr && el.teamLeader==='1'})">
                      <div class="participation__program-name mb-2">
                        <h4 class="h6 mb-1 p-1 bg-info">
                          {{programs.data.filter(function (el) {return el.idNr===entry.programIdNr})[0].name}}<br>
                          <span class="text-primary">
                            {{entry.teamName}}
                            <i v-if=entry.categoryName class="small">({{entry.categoryName}})</i>
                          </span>
                          <router-link v-if="teamData(entry.programIdNr,entry.teamId).note!==null" :to="{name:'userModifyTeam',params: {entryId : entry.entryId}}">
                            <i class="bi bi-pencil-square ms-2 text-danger"></i>
                          </router-link>
                        </h4>
                        <div class="small">
                          <div v-if="teamData(entry.programIdNr,entry.teamId).members.length>0 && teamData(entry.programIdNr,entry.teamId).note===null">
                            <template v-for="member in teamData(entry.programIdNr,entry.teamId).members">
                              {{member.fullName}}<br>
                            </template>
                          </div>
                          <div v-if="teamData(entry.programIdNr,entry.teamId).note!==null" v-html="teamData(entry.programIdNr,entry.teamId).note.replace(/\n/g, '<br />')">
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <h3 v-if="entries.filter(function (el) {return el.idNr===idNr && el.isDeleted===false}).length>0" class="h6 fw-bold text-decoration-underline mb-1">
                  A következő(k)re neveztél:
                </h3>
                <ul class="mb-0">
                  <li v-for="entry in entries.filter(function (el) {return el.idNr===idNr && el.isDeleted===false})" class="d-flex my-1">
                    <div class="participation__program-name">
                      {{programs.data.filter(function (el) {return el.idNr===entry.programIdNr})[0].name}}
                    </div>
                    <div class="participation__cancel-btn" @click="entryDelete(this,entry.entryId,false)">
                      <i class="bi bi-x-circle ms-1 text-danger"></i>
                    </div>
                  </li>
                </ul>
              </div>

            </div>

            <template v-for="guest in guests.filter(function (el) {return el.isDeleted===false && el.ageUnder==='1'})">
              <div class="card-participation mb-3 p-2">

                <div class="d-flex justify-content-between">
                  <div class="card-participation__name">
                    <h2 class="h6 small mb-0">
                      <span class="h5 text-success mb-0" v-if="guest.lastName!==null"><i class="bi bi-person-circle me-2"></i>{{guest.lastName}} {{guest.firstName}}</span>
                      <span class="h5 text-success mb-0" v-else><i class="bi bi-person-circle me-2"></i>Anonim</span>
                      <br>
                      <span v-if="guest.ageUnder==='1'">14 év alatti családtag</span><span v-else>14 év feletti családtag</span>
                    </h2>
                  </div>
                  <div class="card-participation__btn d-flex">
                    <router-link :to="{name:'userParticipate',params: {guestId : guest.idNr}}">
                      <button class="btn btn-primary btn-sm">Nevezés <i class="bi bi-arrow-right-short"></i></button>
                    </router-link>
                  </div>
                </div>


                <h3 v-if="entries.filter(function (el) {return el.idNr===guest.idNr && el.isDeleted===false}).length>0" class="h6 small fw-bold text-decoration-underline mt-3 mb-0">Nevezett:</h3>
                <ul class="mb-0">
                  <li v-for="entry in entries.filter(function (el) {return el.idNr===guest.idNr && el.isDeleted===false})" class="d-flex">
                    <div class="participation__program-name">
                      {{programs.data.filter(function (el) {return el.idNr===entry.programIdNr})[0].name}}
                    </div>
                    <div class="participation__cancel-btn" @click="entryDelete(this,entry.entryId,false)">
                      <i class="bi bi-x-circle ms-1 text-danger"></i>
                    </div>
                  </li>
                </ul>

              </div>
            </template>

          </div>

        </div>

      </div>
    </section>

    <section class="container pb-5" v-if="serverDateTime>programs.firstRegistrationDateTime">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3" id="programs">

          <h2 class="text-center">Csapat nevezés</h2>
          <p class="text-center">
            Szeretnénk, ha minél több helyről neveznétek. Izgalmas versenyek jelentenének mindannyiunk számára emlékezetes, közös élményeket.
          </p>

          <div class="card-participation mb-3 p-2" v-for="(program,index) in teamPrograms">
            <a class="text-decoration-none" data-bs-toggle="collapse" :href="'#program-'+index" role="button" aria-expanded="false" :aria-controls="'program-'+index">
              <h3 class="h5 text-dark mb-0" v-html="program.name"></h3>
              <p class="mb-0">
                <i v-if="program.teams.length>0" class="bi bi-arrow-down-square-fill"></i>
                <i v-else class="bi bi-people"></i>
                Csapatok: {{program.teams.length}} / {{program.teamMaxNumber}}
              </p>
            </a>
            <div class="collapse" :id="'program-'+index" data-bs-parent="#programs">
              <ul class="ms-3 mb-0">
                <li class="my-1" v-for="(team,subIndex) in program.teams">
                  <a class="text-decoration-none" data-bs-toggle="collapse" :href="'#program-'+index+'-team-'+subIndex" role="button" aria-expanded="false" :aria-controls="'program-'+index+'-team-'+subIndex">
                    <i v-if="team.hasOwnProperty('members')===true" class="bi bi-people-fill"></i>
                    <i v-else class="bi bi-people"></i>
                    {{team.teamName}}
                    <i v-if=team.categoryName class="small">({{team.categoryName}})</i>
                    <span v-if="program.noteRequired==='0'">(<span v-if="team.hasOwnProperty('members')===true">{{team.members.length}}</span><span v-else>0</span> / {{program.teamMaxMember}} fő)</span>
                  </a>
                  <ul v-if="program.noteRequired==='0'" class="collapse ms-4" :id="'program-'+index+'-team-'+subIndex" :data-bs-parent="'#program-'+index">
                    <li v-for="member in team.members">{{member.fullName}}</li>
                  </ul>
                  <div v-else class="collapse ms-4" :id="'program-'+index+'-team-'+subIndex" :data-bs-parent="'#program-'+index" v-html="team.note.replace(/\n/g, '<br />')">
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <router-link :to="{name:'userNewTeam'}">
            <button class="btn btn-secondary form-control mt-3 mb-3">Új csapat nevezése</button>
          </router-link>
          <p class="text-center text-secondary">
            A csapatok módosításának, összevonásának, törlésének, a nevezhető csapatszám változtatásának lehetőségét és jogát fenntartjuk.<br>
            Új csapatot - a maximális csapatszám eléréséig - csapatkapitányok nevezhetnek, akik feladata a csapatok megszervezése, a csapattagokkal egyeztetés, és hogy gondoskodjanak arról, a csapatok megfelelő időpontokban a program helyszínén a nevezésnek és a szabályoknak megfelelően megjelenjenek.
          </p>
        </div>
      </div>
    </section>

    <section class="container pb-5">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">

          <router-link :to="{name:'agenda'}">
            <button type="submit" class="btn btn-outline-primary form-control mt-4"><i class="bi bi-arrow-left me-2"></i>{{ generalContents.backBtn }}</button>
          </router-link>

        </div>
      </div>
    </section>

  </main>
  <EntryDeleteAgree :initData = initData></EntryDeleteAgree>

</template>


<script>
import {Response} from "@/classes/response";
import EntryDeleteAgree from '@/views/Modals/EntryDeleteAgree'

export default {
  name: 'UserParticipation',
  props: ['initData'],
  components: {
    EntryDeleteAgree
  },
  data(){
    let loggedIn = this.initData.user.loggedIn
    let permission = this.initData.user.permission
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }

    let serverDateTime = this.initData.user.serverDateTime
    let participationDisplay = this.initData.event.pageDisplay.participation
    if(serverDateTime<participationDisplay && permission!==90){
      this.$router.push({name: 'info'})
    }


    let generalContents = this.initData.lang.general
    let participationContents = this.initData.lang.user.participation
    let idNr = this.initData.user.idNr
    let lastName = this.initData.user.lastName
    let firstName = this.initData.user.firstName
    let guests = this.initData.user.guests
    let teamPrograms = this.initData.programs.data.filter(function (el) {return el.teamEntry==1})
    let programs = this.initData.programs
    let entries = programs.entries

    return {
      idNr,
      lastName,
      firstName,
      generalContents,
      participationContents,
      guests,
      teamPrograms,
      entries,
      programs,
      serverDateTime,
      EntryDeleteAgree
    }
  },
  methods:{
    teamData(programIdNr,teamId){
      let program = this.programs.data.filter(function (el) {return el.idNr===programIdNr})[0]
      return program.teams.filter(function (el) {return el.teamId===teamId})[0]
    },
    entryDelete(e,entryId,agreed) {
      if(agreed===false){
        e.initData.modals.entryAgree = entryId
        e.EntryDeleteAgree.methods.openModal()
        return false
      }

      e.initData.loader = true
      e.$axios.delete('/v1/user/entry/'+entryId+'/')
          .then((response) => {
            let responseData =  new Response().handle(response)
            console.log(responseData)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('redirect')===true){
              e.$router.push({name: responseData.data.redirect})
            }

            if(responseData.status===true) {
              let entry = e.entries.filter(el => { return el.entryId === entryId })
              entry[0].isDeleted = true
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });

    }
  }
}
</script>


<style>

</style>