<template>
  <Head :initData = "initData"></Head>
  <Header v-if="initData.initLoader===false" :initData = "initData"></Header>
  <Loader :initData="initData"/>
  <div class="alert-deck">
    <Alerts :alerts="alert" v-for="alert in initData.alerts"/>
  </div>
  <router-view v-if="initData.initLoader===false" :initData = "initData"/>
  <Footer v-if="initData.initLoader===false && this.$route.path==='/'"></Footer>
</template>

<script>
import {reactive} from 'vue'
import Loader from "./components/Loader"
import Alerts from "./components/Alerts"
import Head from './components/Head'
import Header from "./components/Header"
import Footer from "./components/Footer"
import {Response} from '@/classes/response.js'

export default {
  name: 'App',
  components: {
    Loader, Alerts, Head, Header, Footer
  },
  data(){
    // DEFAULT INIT DATA
    const initData = reactive({
      'user' : null,
      'initLoader' : true,
      'loader' : false,
      'alerts' : [],
      'event' : null,
      'lang' : null,
      'programs' : null,
      'programParticipants' : null,
      'divisions' : null,
      'admin' : null,
      'modals' : {
        'guestAgree' : null
      },
      'camera' : {
        'data' : null,
        'readValue' : null
      }
    })

    const classes = ['.modal-backdrop']

    return {
      initData
    }
  },
  created(){
    this.userInit()
  },
  methods:{
    userInit(){
      this.$axios.get('/v1/user/')
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.initData.lang = responseData.data.lang
              this.initData.event = responseData.data.event
              this.initData.user = responseData.data.user
              this.initData.programs = responseData.data.programs
              this.initData.voluntaryActs = responseData.data.voluntaryActs
              this.initData.divisions = responseData.data.divisions
              this.initData.info = responseData.data.info
              this.initData.info_2 = responseData.data.info_2
              console.log(responseData.data)

              let serverDateTime = this.initData.user.serverDateTime
              let eventData = this.initData.event

              let comingSoonData = eventData.comingSoon
              if(serverDateTime<comingSoonData.start){
                this.$router.push({name: 'comingSoon'})
              }

            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.initLoader = false
          });

    }
  }
}
</script>
<style>

</style>
