<template>

  <main>

    <section class="container pt-4">

      <div class="row">

        <div class="col-12 col-md-6 offset-md-3">
          <h1 class="h3 text-center mb-3">Új csapat nevezése</h1>
          <p class="mb-0"><span class="fw-bold text-secondary">Figyelem!</span> Csapatot csapatkapitányok nevezhetnek. A csapatkapitány feladata, hogy csapata a megfelelő időpontban a program helyszínén a nevezésnek és a szabályoknak megfelelően megjelenjen.</p>
          <p class="fw-bold mb-3">Csapatot akkor nevezz, ha megvan a csapathoz szükséges létszám.</p>
        </div>

        <form @submit.prevent="handleSubmit" class="col-12 col-md-6 offset-md-3">

          <div class="form-group mb-3">
            <label for="programSelect" class="form-label fw-bold text-primary">Melyik programhoz nevezel csapatot?</label>
            <select class="form-select" v-model="programId" :class="programError ? 'is-invalid' : ''" id="programSelect" aria-label="Select program">
              <option value="0">Kérjük, válassz!</option>
              <template v-for="program in programsData">
              <option :value="program.idNr" v-if="program.teamMaxNumber>program.teams.length" v-html="program.name"></option>
              </template>
            </select>
            <div v-if="programError" class="small text-danger">
              {{ programError }}
            </div>
          </div>

          <div class="mb-3" v-show="telephoneRequired">
            <label for="telephone" class="form-label fw-bold text-primary">Telefonszám</label>
            <input class="form-control" v-model="telephone" :class="telephoneError ? 'is-invalid' : ''" id="telephone" aria-label="telephoneInput" placeholder="pl.: +36701234567" maxlength="12" />
            <div v-if="telephoneError" class="small text-danger">
              {{ telephoneError }}
            </div>
            <div id="telephoneHelp" class="form-text">
              Kérjük, add meg a telefonszámod, amin fel tudjuk veled venni a kapcsolatot, ha szükséges.
            </div>
          </div>

          <div class="mb-3" v-show="programCategoryRequired">
            <label for="selectCategory" class="form-label fw-bold text-primary">Kategória</label>
            <select class="form-select" v-model="programCategoryId" :class="programCategoryIdError ? 'is-invalid' : ''" id="selectCategory" aria-label="selectCategory">
              <option value="0">Válassz nevezési kategóriát!</option>
              <option :value="category.id" v-for="category in this.programCategories">{{category.value}}</option>
            </select>
            <div v-if="programCategoryIdError" class="small text-danger">
              {{ programCategoryIdError }}
            </div>
          </div>

          <div class="mb-3">
            <label for="teamNameInput" class="form-label fw-bold text-primary">Csapatnév</label>
            <input type="text" v-model="teamName" :class="teamNameError ? 'is-invalid' : ''" class="form-control" maxlength="20" id="teamNameInput" aria-describedby="teamNameHelp">
            <div v-if="teamNameError" class="small text-danger">
              {{ teamNameError }}
            </div>
            <div id="teamNameHelp" class="form-text">
              Adj olyan ötletes csapatnevet, ami nem összekeverhető más csapat nevével! A név maximum 20 karakterből állhat, speciális karaktereket nem tartalmazhat.<br>
              <span class="fw-bold text-secondary">A csapatnevet később nem tudod módosítani.</span>
            </div>
          </div>

          <div class="mb-5" v-if="noteRequired">
            <label for="teamNoteTextarea" class="form-label fw-bold text-primary">Csapattagok (Saját magadat ne felejtsd ki a felsorolásból!)</label>
            <textarea id="teamNoteTextarea" class="form-control" v-model="note" :class="noteError ? 'is-invalid' : ''" aria-label="Team note" rows="11"
            :placeholder="lastName+' '+firstName+' - Áruház, \n' +
            'Csapattag neve - Áruház, \n' +
            'Csapattag neve - Áruház\n' +
            '... \n' +
            'Kérünk, soronként egy csapattagot adj meg.'"></textarea>
            <div v-if="noteError" class="small text-danger">
              {{ noteError }}
            </div>
            <div id="teamNameHelp" class="form-text">
              Add meg a <span class="fw-bold text-secondary">csapattagok nevét</span>, és hogy melyik <span class="fw-bold text-secondary">szervezeti egységben (áruházban)</span> dolgoznak!
              Ha munkatárs családtagja a csapattag, kérjük, írd a neve mellé, hogy <span class="fw-bold text-secondary">családtag</span>.
            </div>
          </div>

          <div class="d-flex">
            <router-link :to="{name:'userParticipation'}" class="mx-1 w-100">
              <button class="btn btn-outline-primary form-control">Vissza</button>
            </router-link>
            <button type="submit" class="btn btn-primary form-control">Benevezek</button>
          </div>

        </form>

      </div>

    </section>

  </main>

</template>


<script>
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";

export default {
  name: 'UserNewTeam',
  props: ['initData'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }

    let newTeamContents = this.initData.lang.user.newTeam
    let programsData = this.initData.programs.data.filter(function (el) {return el.teamEntry==1})

    let lastName = this.initData.user.lastName
    let firstName = this.initData.user.firstName
    let telephone = this.initData.user.telephone

    return{
      lastName,
      firstName,
      newTeamContents,
      programsData,
      programId: 0,
      programError: null,
      teamName: null,
      teamNameError: null,
      telephoneRequired : false,
      telephone,
      telephoneError : null,
      programCategories : [],
      programCategoryRequired : false,
      programCategoryId : 0,
      programCategoryIdError : null,
      noteRequired : false,
      note : null,
      noteError : null
    }
  },
  watch:{
    programId(){
      if(this.programId!=='0'){
        let programId = this.programId
        let programData =  JSON.parse(JSON.stringify(this.initData.programs.data)).filter(function (el) {return el.idNr === programId})[0]

        if(programData.telephoneRequired==='1'){
          this.telephoneRequired = true
        }else{
          this.telephoneRequired = false
        }

        if(programData.noteRequired==='1'){
          this.noteRequired = true
        }else{
          this.noteRequired = false
        }

        if(programData.categories!==null){
          this.programCategoryRequired = true
          this.programCategories = programData.categories
        }else{
          this.programCategoryRequired = false
        }

      }else{
        this.telephoneRequired = false
        this.programCategoryRequired = false
      }

    },
    programsData(){

    }
  },
  methods: {
    handleSubmit() {
      let validate = new Validate()

      //PROGRAM ID
      this.programError = validate.isSelected(this.programId, this.newTeamContents.errors.programRequired)

      //TELEPHONE
      if(this.telephoneRequired){
        this.telephoneError = validate.telephone(this.telephone,this.newTeamContents.errors.telephoneRequired)
      }

      //CATEGORY
      if(this.programCategoryRequired){
        this.programCategoryIdError = validate.isSelected(this.programCategoryId,this.newTeamContents.errors.programCategoryRequired)
      }

      //TEAMNAME
      this.teamNameError = validate.emptyField(this.teamName,this.newTeamContents.errors.teamNameRequired)

      //NOTE
      if(this.noteRequired){
        this.noteError = validate.emptyField(this.note,this.newTeamContents.errors.noteRequired)
      }


      if (this.programError || this.telephoneError || this.programCategoryIdError || this.teamNameError || this.noteError) {
        return false;
      }

      let formData = new FormData()
      formData.append('program_id', this.programId)
      formData.append('telephone', this.telephone)
      formData.append('category_id', this.programCategoryId)
      formData.append('team_name', this.teamName)
      formData.append('note', this.note)

      this.initData.loader = true
      this.$axios.post('/v1/user/newTeam',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('redirect')===true){
              this.$router.push({name: responseData.data.redirect})
            }

            if(responseData.status===true) {
              this.initData.programs = responseData.data.programs
              this.initData.user.telephone = responseData.data.user.telephone
              this.$router.push({name: 'userParticipation'})
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }
}
</script>


<style>

</style>