<template>

  <main>

    <section class="container pt-3 pb-5">
      <div class="col-12 col-md-6 offset-md-3">

        <form>

          <h1 class="h4 text-center mb-2">Családtag nevezése</h1>
          <p class="small text-center small">A családtag maga válassza ki, melyik sporteseményen akar részt venni, majd az Adatkezelési Tájékoztató és a Felelősségvállalási Nyilatkozat megismerése és elfogadása után nyomja meg a Mentés gombot!</p>

<p class="text-danger">Itt el vannak rejtve mezők: azonosítás, név, születési dátum és nem bekérése.</p>
<!-- TO DO családtag nevének, születési dátumának, nemének bekérése
          <div class="mb-3">
            <label for="guestSelect" class="form-label fw-bold">Családtag kiválasztása</label>
            <select class="form-select" id="selectFamilyMenber" aria-label="Select family member">
              <option selected>Családtag kiválasztása</option>
              <option value="1">családtag (anoním)</option>
              <option value="2">családtag (anoním)</option>
              <option value="3">családtag (anoním)</option>
            </select>
            <small v-if="selectFamilyMenberError" class="text-danger">
              {{ selectFamilyMemberError }}
            </small>
          </div>
          <div class="mb-3">
            <label for="fullNameInput" class="form-label fw-bold">{{newGuestContents.fullNameLabel}}</label>
            <input type="text" class="form-control" :class="fullNameError ? 'is-invalid' : ''" v-model="fullName" id="fullNameInput" aria-describedby="fullNameHelp">
            <small v-if="fullNameError" class="text-danger">
              {{ fullNameError }}
            </small>
          </div>
          <div class="mb-1">
            <div class="d-flex flex-wrap">
              <div class="mb-2 me-4">
                <label for="birthDateInput" class="form-label">{{registrationContents.dateLabel}}</label>
                <input type="date" class="form-control" :class="birthDateError ? 'is-invalid' : ''" v-model="birthDate" id="birthDateInput" aria-describedby="birthDate">
                <small v-if="birthDateError" class="text-danger">
                  {{ birthDateError }}
                </small>
              </div>
              <div class="mb-2">
                <label for="genderInput" class="form-label mb-2">{{registrationContents.genderLabel}}</label>
                <div class="d-flex">
                  <div class="form-check me-3">
                    <input class="form-check-input" type="radio" name="genderInput" value="1" v-model="gender" id="genderFemale">
                    <label class="form-check-label" for="genderFemale">
                      {{registrationContents.genderFemaleLabel}}
                    </label>
                  </div>
                  <div class="form-check me-3">
                    <input class="form-check-input" type="radio" name="genderInput" value="2" v-model="gender" id="genderMale">
                    <label class="form-check-label" for="genderMale">
                      {{registrationContents.genderMaleLabel}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="form-text mb-4">{{registrationContents.genderHelp}}</p>
-->

          <div class="card-program mb-3 p-3">

            <div class="form-floating mb-3">
              <select class="form-select" id="selectProgram" aria-label="Select program">
                <option selected>Válassz programot!</option>
                <template v-for="(program,index) in programsData.filter(function (el) {return el.preRegistration === '1'})">
                  <option value="" v-html="program.name+' '+program.date+' ('+program.startTime+'-'+program.endTime+')'"></option>
                </template>
              </select>
              <label for="floatingSelect">Program, sportesemény</label>
            </div>

            <div class="form-floating mb-3">
              <select class="form-select" id="selectCategory" aria-label="Select category">
                <option selected>Válassz kategóriát!</option>
                <option value="1">Hagyományos</option>
                <option value="2">Vegán</option>
              </select>
              <label for="floatingSelect">Kategória</label>
            </div>

            <div class="form-floating mb-3">
              <select class="form-select" id="selectTeam" aria-label="Select team">
                <option selected>Válassz csapatot!</option>
                <option value="1">Tigrisek</option>
                <option value="2">Vattacukrok</option>
                <option value="3" disabled>Vizilovak (max. létszám elérve)</option>
              </select>
              <label for="floatingSelect">Csapatom</label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="checkPrivacy">
              <label class="form-check-label small" for="checkPrivacy">
                Az Adatkezelési Tájékoztatót megismertem, a személyes adataim kezeléséhez az abban foglaltak szerint hozzájárulok.
              </label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="checkResponsibility">
              <label class="form-check-label small" for="checkResponsibility">
                A felelősségvállalási nyilatkozatot megismertem, a helyszínen a nyilatkozatot aláírom.
                Egészségügyi állapotom megfelelő. Nincs tudomásom olyan körülményről, ami miatt nem javasolt a kiválasztott programon részt vennem.
              </label>
            </div>

          </div>

          <!--<template v-for="(program,index) in programsData.filter(function (el) {return el.preRegistration === '1'})">
            <div class="card-program mb-3 p-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" :id="'ticket-'+index">
                <label class="form-check-label card-program__name" :for="'ticket-'+index">
                  {{program.name}}<br>
                  <small>{{program.date}} {{program.startTime}}-{{program.endTime}} {{program.locationName}}</small>
                </label>
              </div>
            </div>
          </template>-->

          <button @click="submitHandle" class="btn btn-primary form-control mt-3 mb-4">Mentés</button>
          <router-link :to="{name:'userParticipation'}">
            <button class="btn btn-outline-primary form-control mt-2"><i class="bi bi-arrow-left me-2"></i>Vissza</button>
          </router-link>

        </form>
      </div>
    </section>

  </main>

</template>


<script>
export default {
  name: 'UserParticipate',
  props: ['initData','guestId'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }
    let generalContents = this.initData.lang.general
    let participateContents = this.initData.lang.user.participate
    let lastName = this.initData.user.lastName
    let firstName = this.initData.user.firstName
    let programsData = this.initData.programs.data

    return {
      lastName,
      firstName,
      generalContents,
      participateContents,
      programsData
    }
  },
  methods:{
    submitHandle(){

    }
  }
}
</script>


<style>

</style>