<template>

  <main>

    <section class="container py-3">
      <div class="col-12 text-center">
<!--        <h1 class="container text-center">Programok</h1>-->
        <div>
          <p class="mb-2">{{eventData.address}}</p>
          <a v-if="mapSetup.url && serverDateTime>mapDisplay" :href="mapSetup.url" target="_blank">
            <button class="btn btn-outline-primary"><i class="bi bi-map me-2"></i>Térkép megtekintése</button>
          </a>
        </div>
<!--        <div class="text-center">-->
<!--          <router-link :to="{name:'userVoluntary'}">-->
<!--            <button class="btn btn-sm btn-outline-secondary mb-2">-->
<!--              Önkéntes munkára jelentkezem-->
<!--            </button>-->
<!--          </router-link>-->
<!--        </div>-->
      </div>
    </section>

    <section class="container mb-5">
      <div class="row">

        <template v-for="programDate in programsDates">

          <div class="col-12">
            <h2 class="h5 mt-4 mb-2">{{programDate.date}} ({{programDate.day}})</h2>
          </div>

          <div class="col-12 col-md-6">
            <h3 class="text-primary h6 text-uppercase mt-3">Programok</h3>
            <template v-for="program in programsData.filter(function (el) {return el.date == programDate.date && el.highlighted === '0'})">
              <div class="card-program d-flex justify-content-between my-2 p-2">
                <div class="card-program__text">
                  <div class="small">{{program.startTime}}-{{program.endTime}} / {{program.locationName}}</div>
                  <div class="fw-bold" v-html="program.name"></div>
                  <div v-if="program.preRegistration==='1' && serverDateTime<program.regStartTime" class="d-block small text-danger">
                    Előzetes jelentkezés / nevezés szükséges<br>
                    Jelentkezés indul: {{program.regStartTimeDisplay}}
                  </div>
                  <router-link v-else-if="program.preRegistration==='1' && serverDateTime>program.regStartTime && serverDateTime<program.regEndTime" :to="{name:'userParticipation'}" class="d-block small text-danger">
                    Előzetes jelentkezés / nevezés szükséges<br>
                    Jelentkezz most!
                  </router-link>
                  <div v-else-if="program.preRegistration==='1' && serverDateTime>program.regEndTime" class="d-block small text-danger">
                    A jelentkezést lezártuk
                  </div>
<!--                  <div v-else class="small">A program nincs előzetes jelentkezéshez kötve</div>-->
                </div>
                <div class="card-program__btn d-flex align-items-end">
                  <router-link :to="{name:'programDetails',params: {programId : program.idNr}}">
                    <button class="btn btn-info bi bi-text-paragraph d-block"></button>
                  </router-link>
                </div>
              </div>
            </template>
          </div>

          <div class="col-12 col-md-6">
            <h3 class="text-primary h6 text-uppercase mt-3">Sportesemények</h3>
            <template v-for="program in programsData.filter(function (el) {return el.date === programDate.date && el.highlighted === '1' && el.showInAgenda === true})">
              <div class="card-program featured-program d-flex justify-content-between my-2 p-2">
                <div class="card-program__text">
                  <div class="small">{{program.startTime}}-{{program.endTime}} / {{program.locationName}}</div>
                  <div v-if="program.groupName!==null" class="fw-bold" v-html="program.groupName"></div>
                  <div v-else class="fw-bold" v-html="program.name"></div>
                  <div v-if="program.preRegistration==='1' && serverDateTime<program.regStartTime" class="d-block small text-danger">
                    Előzetes jelentkezés / nevezés szükséges<br>
                    Jelentkezés indul: {{program.regStartTimeDisplay}}
                  </div>
                  <router-link v-else-if="program.preRegistration==='1' && serverDateTime>program.regStartTime && serverDateTime<program.regEndTime" :to="{name:'userParticipation'}" class="d-block small text-danger">
                    Előzetes jelentkezés / nevezés szükséges<br>
                    Jelentkezz most!
                  </router-link>
                  <div v-else-if="program.preRegistration==='1' && serverDateTime>program.regEndTime" class="d-block small text-danger">
                    A jelentkezést lezártuk
                  </div>
<!--                  <div v-else class="small">A program nincs előzetes jelentkezéshez kötve</div>-->
                  <template v-if="program.children!==null">
                    <div class="mt-2">
                      <div class="py-1" v-for="children in program.children">
                        <router-link :to="{name:'programDetails',params: {programId : children.idNr}}">
                          <span v-html="children.name"></span>
                        </router-link>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="card-program__btn d-flex align-items-end" v-if="program.groupId === null">
                  <router-link :to="{name:'programDetails',params: {programId : program.idNr}}">
                    <button class="btn btn-info bi bi-text-paragraph d-block"></button>
                  </router-link>
                </div>
              </div>
            </template>
          </div>

        </template>

      </div>
    </section>

  </main>

</template>


<script>
export default {
  name: 'Agenda',
  props: ['initData'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    let permission = this.initData.user.permission
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }

    let serverDateTime = this.initData.user.serverDateTime
    let pageDisplay = this.initData.event.pageDisplay.programs
    if(serverDateTime<pageDisplay && permission!==90){
      this.$router.push({name: 'info'})
    }

    let eventData = this.initData.event
    let programsData = this.initData.programs.data
    let programsDates = this.initData.programs.dates
    let mapSetup = eventData.mapSetup
    let mapDisplay = eventData.pageDisplay.map

    return{
      serverDateTime,
      eventData,
      programsData,
      programsDates,
      mapSetup,
      mapDisplay
    }
  }
}
</script>


<style scoped>
a{
  text-decoration: none;
}
</style>