<template>

  <main>

    <section class="container pt-4 pb-5">

      <form @submit.prevent="handleSubmit" class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4 col-md-4">
        <h1 class="h3 text-center mb-4">{{newGuestContents.title}}</h1>

          <div class="mb-4" v-if="ageUnderRequired">
            <label for="familyMemberAgeRangeInput" class="form-label fw-bold mb-2">Családtagod elmúlt 14 éves?</label>
            <div class="d-flex">
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" v-model="ageUnder" name="familyMemberAgeRangeInput" value="1" id="underAge">
                <label class="form-check-label" for="underAge">
                  14 év alatti
                </label>
              </div>
              <div class="form-check me-3">
                <input class="form-check-input" type="radio" v-model="ageUnder" name="familyMemberAgeRangeInput" value="0" id="aboveAge">
                <label class="form-check-label" for="aboveAge">
                  14. életévét betöltötte
                </label>
              </div>
            </div>
            <small v-if="ageUnderError" class="text-danger">
              {{ ageUnderError }}
            </small>
          </div>

        <div class="mb-3" v-if="fullNameRequired">
          <label for="fullNameInput" class="form-label fw-bold">{{newGuestContents.fullNameLabel}}</label>
          <input type="text" class="form-control" :class="fullNameError ? 'is-invalid' : ''" v-model="fullName" id="fullNameInput" aria-describedby="fullNameHelp">
          <small v-if="fullNameError" class="text-danger">
            {{ fullNameError }}
          </small>
        </div>

        <div class="mb-1" v-if="birthdateRequired">
          <div class="d-flex flex-wrap">
            <div class="mb-2 me-4">
              <label for="birthDateInput" class="form-label fw-bold">{{newGuestContents.dateLabel}}</label>
              <input type="date" class="form-control" :class="birthDateError ? 'is-invalid' : ''" v-model="birthDate" id="birthDateInput" aria-describedby="birthDate">
              <small v-if="birthDateError" class="text-danger">
                {{ birthDateError }}
              </small>
            </div>
            <div class="mb-2" v-if="genderRequired">
              <label for="genderInput" class="form-label fw-bold mb-2">{{newGuestContents.genderLabel}}</label>
              <div class="d-flex">
                <div class="form-check me-3">
                  <input class="form-check-input" type="radio" name="genderInput" value="2" v-model="gender" id="genderFemale">
                  <label class="form-check-label" for="genderFemale">
                    {{newGuestContents.genderFemaleLabel}}
                  </label>
                </div>
                <div class="form-check me-3">
                  <input class="form-check-input" type="radio" name="genderInput" value="1" v-model="gender" id="genderMale">
                  <label class="form-check-label" for="genderMale">
                    {{newGuestContents.genderMaleLabel}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-if="genderRequired" class="form-text small mb-4">{{newGuestContents.genderHelp}}</p>

        <div class="mb-3 form-check" v-if="policyRequired">
          <input type="checkbox" class="form-check-input" :class="policyErrorText ? 'is-invalid' : ''" id="policyCheck" v-model="policy">
          <label class="form-check-label small" for="policyCheck">Családtagom az <router-link :to="{name:'adminEventDetails'}">Adatkezelési Tájékoztató</router-link>t megismerte, személyes adatai kezeléséhez kifejezett hozzájárulását adja.</label>
          <br>
          <small v-if="policyErrorText" class="text-danger">
            {{ policyErrorText }}
          </small>
        </div>

        <button type="submit" class="btn btn-primary form-control mb-2">{{generalContents.saveBtn}}</button>
        <router-link :to="{name:'userAccount'}">
          <button class="btn btn-outline-secondary form-control mb-3"><i class="bi bi-arrow-left me-2"></i>{{generalContents.backBtn}}</button>
        </router-link>

      </form>
    </section>

  </main>

</template>


<script>
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";

export default {
  name: 'UserNewGuest',
  props: ['initData'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }

    let guestInviteSetup = this.initData.event.guestInvite
    let ageUnderRequired = guestInviteSetup.ageUnderRequired
    let fullNameRequired = guestInviteSetup.fullNameRequired
    let birthdateRequired = guestInviteSetup.birthdateRequired
    let genderRequired = guestInviteSetup.genderRequired
    let policyRequired = guestInviteSetup.policyRequired

    let generalContents = this.initData.lang.general
    let newGuestContents = this.initData.lang.user.newGuest

    return {
      generalContents,
      newGuestContents,
      ageUnderRequired,
      fullNameRequired,
      birthdateRequired,
      genderRequired,
      policyRequired,
      policy : false,
      policyErrorText : null,
      fullName : null,
      fullNameError : null,
      birthDate : null,
      birthDateError : null,
      gender : 0,
      ageUnder : -1,
      ageUnderError : 0
    }
  },methods:{
    handleSubmit() {
      let validate = new Validate()

      // AGE UNDER
      if(this.ageUnderRequired){
        this.ageUnderError = validate.ageUnder(this.ageUnder, this.newGuestContents.errors.ageUnderRequired)
      }

      // FULLNAME
      if(this.fullNameRequired){
        this.fullNameError = validate.emptyField(this.fullName, this.newGuestContents.errors.fullNameRequired)
      }

      // POLICY
      if(this.policyRequired){
        this.policyErrorText = validate.checkboxChecked(this.policy,this.newGuestContents.errors.policyRequired)
      }


      if (this.fullNameError || this.policyErrorText || this.ageUnderError) {
        return false;
      }


      let formData = new FormData()

      formData.append('full_name', this.fullName)
      formData.append('birth_date', this.birthDate)
      formData.append('gender', this.gender)
      formData.append('policy', this.policy)
      formData.append('age_under', this.ageUnder)

      this.initData.loader = true
      this.$axios.post('/v1/user/guest',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              this.initData.user.guests.push(responseData.data.guest)
              this.$router.push({name: 'userAccount'})
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });

    }
  }
}
</script>


<style>

</style>