<template>

  <main>

    <section class="container pb-5">

      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3">
          <h1 class="container h2 text-center mb-2 pt-3"><i class="bi bi-ticket-perforated me-2"></i>Jegyek</h1>
          <p class="small text-center mb-4">A jegye(ke)t a QR-kódod felmutatásával tudod beváltani.</p>
        </div>
      </div>

      <template v-for="member in members">
        <div class="mb-4">
          <div class="row">
            <div class="col-12">
              <h2 class="h5 mb-3">{{ member.lastName }} {{member.firstName}}</h2>
            </div>
          </div>
          <template v-if="ticketTypes.length>0" v-for="ticketType in ticketTypes">
            <div class="row">
              <template v-for="userTicket in usersTickets.filter(function (el) {return el.idNr === member.idNr && el.ticketId===ticketType.ticketId})">
              <div class="col-12 col-md-6 col-lg-4">
                  <div class="card-ticket" :class="{'text-success' : userTicket.used!=null,'bg-info' : ticketType.active!=true}">
                    <div class="card-ticket__icon">
                      <i :class="ticketType.icon"></i>
                    </div>
                    <div class="card-ticket__text">
                      <div class="card-ticket__name">
                        {{ticketType.name}}<template v-if="userTicket.used"><i class="bi bi-check"></i></template>
                      </div>
                      <div class="card-ticket__validity">
                        <template v-if="userTicket.used!=null">
                          Beváltva:<br>
                          {{userTicket.used}}
                        </template>
                        <template v-else>
                          Beváltható:<br>
                          {{ticketType.start}} - {{ticketType.end}}
                        </template>

                      </div>
                    </div>
                  </div>
              </div>
              </template>
            </div>
          </template>
        </div>
      </template>

    </section>

    <div class="bottom-buttons shadow">
      <div class="container">
        <div class="col-12 col-md-6 offset-md-3 d-flex justify-content-center align-items-center">
          <router-link :to="{name:'agenda'}">
            <button class="btn btn-outline-secondary me-3"><i class="bi bi-arrow-left me-2"></i>Vissza</button>
          </router-link>
          <router-link :to="{name:'userQrCodes'}">
            <button class="btn btn-secondary">Beváltás</button>
          </router-link>
        </div>
      </div>
    </div>

  </main>

</template>


<script>
export default {
  name: 'userTickets',
  props: ['initData'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }
    let generalContents = this.initData.lang.general
    let ticketsContents = this.initData.lang.user.tickets
    let guests = this.initData.user.guests
    let tickets = this.initData.user.tickets
    let ticketTypes = tickets.types
    let usersTickets = tickets.users
    let user = this.initData.user

    let members = JSON.parse(JSON.stringify(guests))
    members.unshift(user)


    return {
      ticketTypes,
      usersTickets,
      generalContents,
      members
    }
  }
}
</script>


<style>

</style>