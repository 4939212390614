<template>

  <div class="modal fade" id="eventApplyModal" tabindex="-1" aria-labelledby="eventApplyModalLabel" aria-hidden="true" ref="modalRef">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="eventApplyModalLabel">Megerősítés szükséges</h5>
        </div>
        <div class="modal-body">
          <p>Amennyiben nem tudsz eljönni a rendvezvényre, családtagjaid részvételét is töröljük. Emellett a saját és a családtagjaid jelentkezése / nevezése is törlésre kerül.</p>
          <p><strong>Biztos, hogy nem tudsz részt venni a rendezvényen?</strong></p>
        </div>
        <div class="modal-footer">
          <button type="button" @click="agree" class="btn btn-danger" data-bs-dismiss="modal">Nem tudok részt venni</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Mégse</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Modal } from 'bootstrap'
import UserAccount from '@/views/User/Account'

export default {
  name: 'EventApplyAgree',
  data(){
    return{
      modalInstance : null
    }
  },
  methods:{
    agree(){
      this.closeModal()
      UserAccount.methods.handleSubmit(this.$parent,true)
    },
    openModal(){
      this.modalInstance = new Modal(document.getElementById('eventApplyModal'))
      this.modalInstance.show()
    },
    closeModal(){
      this.modalInstance = new Modal(document.getElementById('eventApplyModal'))
      this.modalInstance.hide();
    }
  }
}
</script>
<style>

</style>