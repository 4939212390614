<template>

  <footer>
    <div class="container">
      <ul class="d-flex flex-column small mb-0">
        <li class=""><a href="/data/1/hasznalati-feltetelek-tesco-csapatnap-2024.pdf" target="_blank">Használati feltételek</a></li>
        <li class=""><a href="/data/1/adatkezelesi-tajekoztato-tesco-csapatnap-2024.pdf" target="_blank">Adatkezelési tájékoztató</a></li>
      </ul>
    </div>
  </footer>

</template>

<script>
export default {
  name: 'Footer',
  components: {

  }
}
</script>
<style>

</style>