<template>

  <main>

    <section class="container py-4">
      <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">

        <h1 class="h4 text-center">{{myQrCodesContents.title}}</h1>
        <div class="small text-center" v-html="myQrCodesContents.txt"></div>

        <div id="carouselIndicators" class="carousel carousel-dark slide bg-white py-2 mb-5" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="1000000">
              <h2 class="h4 text-center mt-2 mb-3 lh-1">{{fullName}}<!--<br><small>meghívott</small>--></h2>
              <div class="d-flex justify-content-center align-items-center bg-info mb-2">
                <div v-html="qrCode" class="qr-code"></div>
              </div>
              <div class="text-center mb-1">{{eventId}}-{{idNr}}</div>
              <!--<div class="text-center mb-3 p-1 text-white bg-success border">Beléptél a rendezvényre</div>-->
            </div>
            <template v-if="guests.length>0" v-for="guest in guests">
              <div class="carousel-item" data-bs-interval="1000000">
                <h2 class="h4 text-center mt-2 mb-3 lh-1">{{guest.lastName}} {{guest.firstName}}<br><small>{{fullName}} vendége</small></h2>
                <div class="d-flex justify-content-center align-items-center bg-info mb-2">
                  <div v-html="guest.qrCode" class="qr-code"></div>
                </div>
                <div class="text-center mb-1">{{eventId}}-{{guest.idNr}}</div>
                <!--<div class="text-center mb-3 p-1 text-white bg-danger border">Még nem léptél be a rendezvényre</div>-->
              </div>
            </template>
          </div>
          <button v-if="guests.length>0" class="carousel-control-prev" type="button" data-bs-target="#carouselIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Előző</span>
          </button>
          <button v-if="guests.length>0" class="carousel-control-next" type="button" data-bs-target="#carouselIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Következő</span>
          </button>
          <div v-if="guests.length>0" class="py-4 position-relative">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <template v-for="(guest,index) in guests">
                <button type="button" data-bs-target="#carouselIndicators" :data-bs-slide-to="index+1" :aria-label="'Slide '+(index+2)"></button>
              </template>
            </div>
          </div>
        </div>

        <div class="print-btn-wrapper text-center">
          <button onclick="window.print()" class="btn btn-info form-control"><i class="bi bi-printer h4 d-block mx-auto mb-0"></i></button>
        </div>

      </div>
    </section>

    <section class="container pb-5">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">

          <router-link :to="{name:'agenda'}">
            <button type="submit" class="btn btn-outline-primary form-control mt-4"><i class="bi bi-arrow-left me-2"></i>{{ generalContents.backBtn }}</button>
          </router-link>

        </div>
      </div>
    </section>

  </main>

</template>


<script>
export default {
  name: 'QrCodes',
  props: ['initData'],
  data(){
    let eventId = this.initData.event.id
    let loggedIn = this.initData.user.loggedIn
    let permission = this.initData.user.permission
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }

    let serverDateTime = this.initData.user.serverDateTime
    let qrCodesDisplay = this.initData.event.pageDisplay.qrCode
    if(serverDateTime<qrCodesDisplay && permission!==90){
      this.$router.push({name: 'info'})
    }

    let myQrCodesContents = this.initData.lang.user.myQrCodes

    let idNr = this.initData.user.idNr
    let qrCode = this.initData.user.qrCode
    let lastName = this.initData.user.lastName
    let firstName = this.initData.user.firstName
    // let divisionId = this.initData.user.divisionId
    let guests = this.initData.user.guests.filter(function (el) {return el.qrCode!==null})
    let generalContents = this.initData.lang.general

    return{
      myQrCodesContents,
      eventId,
      qrCode,
      idNr,
      fullName : lastName+' '+firstName,
      guests,
      generalContents
    }
  }
}
</script>


<style>

</style>