<template>

  <header class="bg-primary shadow">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
<!--        TODO router link to / or agenda if loggedIn or admin etc-->
        <router-link :to="{name: 'agenda'}" class="col-3 d-flex align-items-center my-2 text-light text-decoration-none">
          <img class="brand-logo" src="../assets/events/1/img/header-logo-clr.svg" alt="Tesco Csapatnap 2024.">
        </router-link>
        <div class="col-6 h6 text-primary fw-bold text-center mb-0 ms-1">
          Csapatnap 2024<span class="text-secondary">.</span>
        </div>
        <ul class="col-3 nav justify-content-end">
          <li v-if="loggedIn &&  serverDateTime>=pageDisplay.profile">
            <router-link :to="{name:'userAccount'}">
              <i class="bi bi-person h4 text-primary d-block mx-auto mb-1"></i>
            </router-link>
          </li>
          <li v-if="loggedIn" @click="this.logOut(this)">
              <i class="bi bi-power h4 text-primary d-block mx-auto ms-3 mb-1"></i>
          </li>
        </ul>
      </div>
    </div>
  </header>

  <nav v-if="loggedIn===true || disableMenuRouteNames.includes($route.name) === true"
    class="main-menu bg-light shadow">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-end"
           :class="{'flex-wrap' : permission===0}">
        <ul class="nav justify-content-around justify-content-md-center col-12 my-2 my-md-0"
            :class="{'justify-content-around' : permission===0,'nav--admin' : permission===90,'align-items-center' : permission===90}">
          <template v-if="permission!==50">
            <li v-if="serverDateTime>=pageDisplay.programs">
              <router-link :to="{name:'agenda'}" class="nav-link">
                <i class="bi bi-card-list bi-animation-flip h4 d-block mx-auto mb-1"></i>
                Program
              </router-link>
            </li>
            <li v-if="serverDateTime>=pageDisplay.participation">
              <router-link :to="{name:'userParticipation'}" class="nav-link">
                <i class="bi bi-puzzle bi-animation-pulse h4 d-block mx-auto mb-1"></i>
                Nevezés
              </router-link>
            </li>
            <li v-if="serverDateTime>=pageDisplay.info">
              <router-link :to="{name:'info'}" class="nav-link">
                <i class="bi bi-info-circle bi-animation-pulse h4 d-block mx-auto mb-1"></i>
                Info
              </router-link>
            </li>
            <li v-if="serverDateTime>=pageDisplay.info_2">
              <router-link :to="{name:'info_2'}" class="nav-link">
                <i class="bi bi-wallet bi-animation-pulse h4 d-block mx-auto mb-1"></i>
                Költségtérítés
              </router-link>
            </li>
            <li v-if="serverDateTime>=pageDisplay.map">
              <router-link :to="{name:'map'}" class="nav-link">
                <i class="bi bi-map bi-animation-flip h4 d-block mx-auto mb-1"></i>
                Térkép
              </router-link>
            </li>
            <!--<li>
              <router-link :to="{name:'userGuests'}" class="nav-link">
                <i class="bi bi-people bi-animation-pulse h4 d-block mx-auto mb-1"></i>
                Családtagok
              </router-link>
            </li>-->
            <!--<li>
              <router-link :to="{name:'userTickets'}" class="nav-link">
                <i class="bi bi-ticket-perforated bi-animation-shake h4 d-block mx-auto mb-1"></i>
                Jegyek
              </router-link>
            </li>-->
            <li v-if="serverDateTime>=pageDisplay.qrCode">
              <router-link :to="{name:'userQrCodes'}" class="nav-link">
                <i class="bi bi-qr-code bi-animation-pulse h4 d-block mx-auto mb-1"></i>
                QR-kód
              </router-link>
            </li>
          </template>

          <template v-if="permission===50 || permission===90">
            <li>
              <router-link :to="{name:'registrator'}" class="nav-link">
                <i class="bi bi-qr-code-scan h4 d-block mx-auto mb-1"></i>
                Beléptetés
              </router-link>
            </li>

          </template>

          <template v-if="permission===90">
            <li>
              <router-link :to="{name:'adminEventStat'}" class="nav-link">
                <i class="bi bi-bar-chart h4 d-block mx-auto mb-1"></i>
                Statisztika
              </router-link>
            </li>
            <!--<li>
              <router-link :to="{name:'registrator'}" class="nav-link">
                <i class="bi bi-people h4 d-block mx-auto mb-1"></i>
                Vendégek
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'adminTickets'}" class="nav-link">
                <i class="bi bi-ticket-perforated h4 d-block mx-auto mb-1"></i>
                Jegyek
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'adminProgramList'}" class="nav-link">
                <i class="bi bi-card-list h4 d-block mx-auto mb-1"></i>
                Programok
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'adminLocationList'}" class="nav-link">
                <i class="bi bi-pin-map h4 d-block mx-auto mb-1"></i>
                Helyszínek
              </router-link>
            </li>
            <li>
              <router-link :to="{name:'adminProviderList'}" class="nav-link">
                <i class="bi bi-shop-window h4 d-block mx-auto mb-1"></i>
                Szolgáltatók
              </router-link>
            </li>-->
          </template>
        </ul>
      </div>
    </div>
  </nav>



</template>

<script>
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";

export default {
  name: 'Header',
  props: ['initData'],
  components: {

  },
  data() {
    let disableMenuRouteNames = [
        'userNewGuest', 'userQrCodes', 'userTickets', 'userCustomQrCode', 'userParticipate',
        'supervisor', 'supervisorCodeCheckResponse',
        'registrator', 'registratorCodeCheckResponse', 'registratorCodeActivateResponse', 'registratorNewGuest',
        'provider', 'providerCodeCheckResponse', 'providerCodeActivateResponse',
    ]
    let serverDateTime = this.initData.user.serverDateTime
    let eventData = this.initData.event
    let pageDisplay = eventData.pageDisplay

    return {
      serverDateTime,
      pageDisplay,
      disableMenuRouteNames
    }
  },
  computed: {
    loggedIn(){
      return this.initData.user.loggedIn
    },
    permission(){
      return this.initData.user.permission
    }
  },
  methods:{
    logOut(e){
      e.initData.loader = true
      e.$axios.post('/v1/user/logOut')
          .then((response) => {
            let responseData =  new Response().handle(response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              e.initData.user.loggedIn = false
              e.$router.push({name: 'login'})
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });
    }
  }
}
</script>
<style>

</style>