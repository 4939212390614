<template>

  <main>

    <section class="container pb-5">
      <div class="col-12 col-md-6 offset-md-3">

        <h1 class="h3 text-center mb-2 pt-3">Önkéntes munkák</h1>
        <p class="small text-center small d-none">Add meg a kért adatokat,<br>majd nyomd meg a Jelentkezés beküldése gombot!</p>

        <div class="mb-4">
          <h2 v-if="voluntaryActsData.filter(function (el) {return el.userEntry!==false}).length>0" class="h6 fw-bold text-decoration-underline mb-1">
            A következő önkéntes feladat(ok)ra jelentkeztél:
          </h2>
          <ul class="mb-0">
            <li v-for="entry in voluntaryActsData.filter(function (el) {return el.userEntry!==false})" class="d-flex my-1">
              <div class="participation__program-name">
                {{entry.name}} ({{entry.date}} {{entry.startTime}}-{{entry.endTime}})
              </div>
              <div class="participation__cancel-btn" @click="entryDelete(this,entry.userEntry.entryId,false)">
                <i class="bi bi-x-circle ms-1 text-danger"></i>
              </div>
            </li>
          </ul>
        </div>

        <form @submit.prevent="submitHandle">

          <div class="card-program mb-3 p-3">

            <h2 class="h4 mb-2 fw-bold">
              {{userFullName}} önkéntes feladatra jelentkezés
            </h2>

            <div class="form-floating mb-3">
              <select class="form-select" id="selectVoluntayAct" v-model="voluntaryActId" aria-label="selectVoluntayAct">
                <option value="0">Válassz az önkéntes feladatok közül!</option>
                <option v-for="(voluntaryAct) in voluntaryActsData" :value="voluntaryAct.id" :disabled="parseInt(voluntaryAct.members)>=parseInt(voluntaryAct.maxVoluntaryNumber) || voluntaryAct.userEntry!==false">
                  {{voluntaryAct.name}} ({{voluntaryAct.date}} {{voluntaryAct.startTime}}-{{voluntaryAct.endTime}}) {{voluntaryAct.members}}/{{voluntaryAct.maxVoluntaryNumber}} fő
                </option>
              </select>
              <label for="selectVoluntayAct" class="fw-bold text-primary">Önkéntes feladat</label>
              <div v-if="voluntaryActIdError" class="small text-danger">
                {{ voluntaryActIdError }}
              </div>
            </div>

            <div class="mb-3" v-if="telephoneRequired">
              <label for="telephoneInput" class="form-label fw-bold text-primary">Telefonszám*</label>
              <input class="form-control" id="telephoneInput" v-model="telephone" :class="telephoneError ? 'is-invalid' : ''" aria-label="telephoneHelp" />
              <div v-if="telephoneError" class="small text-danger">
                {{ telephoneError }}
              </div>
              <div id="telephoneHelp" class="form-text">
                Kérjük, add meg a telefonszámod, amin keresztül felvehetjük veled a kapcsolatot.
              </div>
            </div>

            <div class="mb-3" v-if="emailRequired">
              <label for="emailInput" class="form-label fw-bold text-primary">E-mail*</label>
              <input class="form-control" id="emailInput" v-model="email" :class="emailError ? 'is-invalid' : ''" aria-label="emailHelp" />
              <div v-if="emailError" class="small text-danger">
                {{ emailError }}
              </div>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="responsibility" id="checkResponsibility">
              <label class="form-check-label" for="checkResponsibility">
                Az önkéntes feladat helyszínén 5 perccel a kezdés előtt, a kijelölt vezető utasításait követve vállalom a feladat ellátását. A feladatra jelentkezés során figyelembe vettem, hogy az önkéntes feladat ellátásának időtartama alatt nem tudok részt venni a rendezvény programjain, sporteseményein.
              </label>
            </div>
            <div v-if="responsibilityError" class="small text-danger">
              {{ responsibilityError }}
            </div>
          </div>

          <button class="btn btn-primary form-control mt-3 mb-4">Jelentkezés beküldése</button>
          <router-link :to="{name:'agenda'}">
            <button class="btn btn-outline-primary form-control mt-2"><i class="bi bi-arrow-left me-2"></i>Vissza</button>
          </router-link>

        </form>
      </div>
    </section>

    <VoluntaryEntryDeleteAgree :initData = initData></VoluntaryEntryDeleteAgree>
  </main>

</template>


<script>
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";
import VoluntaryEntryDeleteAgree from "@/views/Modals/VoluntaryEntryDeleteAgree.vue";

export default {
  name: 'UserVoluntaryActs',
  components: {VoluntaryEntryDeleteAgree},
  props: ['initData','guestId'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }
    let voluntaryActsContents = this.initData.lang.user.voluntaryActs
    let generalContents = this.initData.lang.general
    let lastName = this.initData.user.lastName
    let firstName = this.initData.user.firstName
    let idNr = this.initData.user.idNr
    let telephone = this.initData.user.telephone
    let userFullName = lastName+' '+firstName


    return {
      idNr,
      userFullName,
      generalContents,
      voluntaryActsContents,
      voluntaryActId : 0,
      voluntaryActIdError : null,
      telephone,
      telephoneError : null,
      telephoneRequired : false,
      email : null,
      emailError : null,
      emailRequired : false,
      responsibility : false,
      responsibilityError : null,
      VoluntaryEntryDeleteAgree
    }
  },
  computed:{
    voluntaryActsData(){
      return this.initData.voluntaryActs.data
    }
  },
  watch:{
    voluntaryActId(){
      if(this.voluntaryActId!=='0') {
        if (this.voluntaryActId !== 0) {
          let voluntaryActId = this.voluntaryActId
          let voluntaryActData = JSON.parse(JSON.stringify(this.initData.voluntaryActs.data)).filter(function (el) {return el.id === voluntaryActId})[0]

          if (voluntaryActData.telephoneRequired === '1') {
            this.telephoneRequired = true
          } else {
            this.telephoneRequired = false
          }

          if (voluntaryActData.emailRequired === '1') {
            this.emailRequired = true
          } else {
            this.emailRequired = false
          }

        }
      }else{
        this.telephoneRequired = false
      }

    }
  },
  methods:{
    submitHandle(){
      let validate = new Validate()
      //VOLUNTARY ACT ID
      this.voluntaryActIdError = validate.isSelected(this.voluntaryActId,this.voluntaryActsContents.errors.voluntaryActRequired)

      //TELEPHONE
      if(this.telephoneRequired){
        this.telephoneError = validate.telephone(this.telephone,this.voluntaryActsContents.errors.telephoneRequired)
      }

      //EMAIL
      if(this.emailRequired){
        this.emailError = validate.email(this.email,this.voluntaryActsContents.errors.emailRequired)
      }

      //RESPONSIBILITY
      this.responsibilityError = validate.checkboxChecked(this.responsibility,this.voluntaryActsContents.errors.responsibilityRequired)

      if (this.fullNameError || this.voluntaryActIdError || this.telephoneError || this.emailError || this.responsibilityError) {
        return false;
      }

      let formData = new FormData()
      formData.append('user_id_nr', this.idNr)
      formData.append('voluntary_act_id', this.voluntaryActId)
      formData.append('telephone', this.telephone)
      formData.append('email', this.email)
      formData.append('responsibility', this.responsibility)

      this.initData.loader = true
      this.$axios.post('/v1/user/voluntaryParticipate',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('redirect')===true){
              this.$router.push({name: responseData.data.redirect})
            }

            if(responseData.status===true) {
              let voluntaryActsData = responseData.data.voluntaryActsData.data
              if(voluntaryActsData!==null){
                this.initData.voluntaryActs.data = voluntaryActsData
              }

              let user = responseData.data.user
              if(user!==null){
                this.initData.user.telephone = responseData.data.user.telephone
              }

              this.voluntaryActId = 0
              this.telephoneRequired = false
              this.emailRequired = false
              this.responsibility = false
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    },
    entryDelete(e,entryId,agreed) {
      if(agreed===false){
        e.initData.modals.voluntaryEntryAgree = entryId
        e.VoluntaryEntryDeleteAgree.methods.openModal()
        return false
      }

      e.initData.loader = true
      e.$axios.delete('/v1/user/voluntaryParticipate/'+entryId+'/')
          .then((response) => {
            let responseData =  new Response().handle(response)
            console.log(responseData)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('redirect')===true){
              e.$router.push({name: responseData.data.redirect})
            }

            if(responseData.status===true) {
              e.initData.voluntaryActs.data = responseData.data.voluntaryActsData.data
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });

    }
  }
}
</script>


<style>

</style>