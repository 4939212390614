<template>

  <main>

    <section class="container py-3">

      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-2">
          <h1 class="h4 text-center mb-3">Költségtérítés</h1>
          <p v-if="serverDateTime<pageDisplay || infoData===null" class="text-danger text-center fw-bold">A költségtérítéssel kapcsolatos információkat ezen a felületen követheted.</p>
          <div v-else v-html="infoData"></div>
        </div>
      </div>

    </section>

    <section class="container pb-5" v-if="serverDateTime>agendaDisplay">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <router-link :to="{name:'agenda'}">
            <button type="submit" class="btn btn-outline-primary form-control mt-4"><i class="bi bi-arrow-left me-2"></i>{{ generalContents.backBtn }}</button>
          </router-link>

        </div>
      </div>
    </section>

  </main>

</template>


<script>
export default {
  name: 'Results',
  props: ['initData'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    let permission = this.initData.user.permission
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }

    let serverDateTime = this.initData.user.serverDateTime
    let pageDisplay = this.initData.event.pageDisplay.info_2
    let agendaDisplay = this.initData.event.pageDisplay.programs
    if(serverDateTime<pageDisplay && permission!==90){
      this.$router.push({name: 'login'})
    }

    let generalContents = this.initData.lang.general
    let eventData = this.initData.event
    let infoData = this.initData.info_2

    return {
      serverDateTime,
      generalContents,
      pageDisplay,
      agendaDisplay,
      infoData
    }
  }
}
</script>


<style>

</style>