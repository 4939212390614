<template>

  <main>

    <section class="container pb-5">
      <div class="col-12 col-md-6 offset-md-3">

        <h1 class="h3 text-center mb-2 pt-3">Egyéni nevezés</h1>
        <p class="small text-center small">Add meg a kért adatokat,<br>majd nyomd meg a Benevezek gombot!</p>

        <form @submit.prevent="submitHandle">

          <div class="card-program mb-3 p-3">

            <div class="mb-3" v-if="userFullName===null">
              <label for="fullNameInput" class="form-label fw-bold mb-1 text-primary">
                Név / Családtag neve
              </label>
              <input type="text" class="form-control" :class="fullNameError ? 'is-invalid' : ''" v-model="fullName" id="fullNameInput" aria-describedby="fullNameHelp">
              <div v-if="fullNameError" class="small text-danger">
                {{ fullNameError }}
              </div>
              <div id="fullNameHelp" class="form-text">
                Kérjük, add meg családtagod teljes nevét.
              </div>
            </div>
            <h2 class="h4 mb-2 fw-bold" v-else>
              {{userFullName}}
            </h2>

            <div class="mb-1">
              <div class="d-flex flex-wrap">
                <div class="mb-2 me-4">
                  <label for="birthDateInput" class="form-label fw-bold text-primary">{{participateContents.birthDateLabel}}</label>
                  <input type="date" class="form-control" :class="birthDateError ? 'is-invalid' : ''" v-model="birthDate" id="birthDateInput" aria-describedby="birthDate">
                  <small v-if="birthDateError" class="text-danger">
                    {{ birthDateError }}
                  </small>
                </div>
                <div class="mb-2">
                  <label for="genderInput" class="form-label fw-bold text-primary mb-2">{{participateContents.genderLabel}}</label>
                  <div class="d-flex">
                    <div class="form-check me-3">
                      <input class="form-check-input" type="radio" name="genderInput" value="2" v-model="gender" id="genderFemale">
                      <label class="form-check-label" for="genderFemale">
                        {{participateContents.genderFemaleLabel}}
                      </label>
                    </div>
                    <div class="form-check me-3">
                      <input class="form-check-input" type="radio" name="genderInput" value="1" v-model="gender" id="genderMale">
                      <label class="form-check-label" for="genderMale">
                        {{participateContents.genderMaleLabel}}
                      </label>
                    </div>
                  </div>
                  <small v-if="genderError" class="text-danger">
                    {{ genderError }}
                  </small>
                </div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <select class="form-select" id="selectProgram" v-model="programId" aria-label="selectProgram">
                <option value="0">Válassz programot!</option>
                <option v-for="(program) in programsData" :value="program.idNr" v-html="program.name+' '+program.date+' ('+program.startTime+'-'+program.endTime+')'"></option>
              </select>
              <label for="floatingSelect" class="fw-bold text-primary">Program</label>
              <div v-if="programIdError" class="small text-danger">
                {{ programIdError }}
              </div>
            </div>

            <div class="mb-3" v-if="telephoneRequired">
              <label for="telephoneInput" class="form-label fw-bold text-primary">Telefonszám</label>
              <input class="form-control" id="telephoneInput" v-model="telephone" :class="telephoneError ? 'is-invalid' : ''" aria-label="telephone" />
              <div v-if="telephoneError" class="small text-danger">
                {{ telephoneError }}
              </div>
              <div id="telephoneHelp" class="form-text">
                Kérjük, add meg a telefonszámot, amin szükség esetén felvehetjük a kapcsolatot.
              </div>
            </div>

            <div class="form-floating mb-3" v-if="teamRequired">
              <select class="form-select" id="selectTeam" v-model="teamId" :class="teamIdError ? 'is-invalid' : ''" aria-label="Select team">
                <option value="0">Válaszd ki a csapatod!</option>
                <option :value="team.teamId" v-for="team in programTeams">{{team.teamName}}</option>
              </select>
              <label for="selectTeam" class="fw-bold text-primary">Csapatom</label>
              <div v-if="teamIdError" class="small text-danger">
                {{ teamIdError }}
              </div>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="responsibility" id="checkResponsibility">
              <label class="form-check-label" for="checkResponsibility">
                Egészségügyi állapotom megfelelő. Nincs tudomásom olyan körülményről, ami miatt nem javasolt a kiválasztott programon részt vennem.
              </label>
            </div>
            <div v-if="responsibilityError" class="small text-danger">
              {{ responsibilityError }}
            </div>
          </div>

<!--          <template v-for="(program,index) in programsData.filter(function (el) {return el.preRegistration === '1'})">-->
<!--            <div class="card-program mb-3 p-2">-->
<!--              <div class="form-check">-->
<!--                <input class="form-check-input" type="checkbox" value="" :id="'ticket-'+index">-->
<!--                <label class="form-check-label card-program__name" :for="'ticket-'+index">-->
<!--                  {{program.name}}<br>-->
<!--                  <small>{{program.date}} {{program.startTime}}-{{program.endTime}} {{program.locationName}}</small>-->
<!--                </label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->

          <button class="btn btn-primary form-control mt-3 mb-4">Benevezek</button>
          <router-link :to="{name:'userParticipation'}">
            <button class="btn btn-outline-primary form-control mt-2"><i class="bi bi-arrow-left me-2"></i>Vissza</button>
          </router-link>

        </form>
      </div>
    </section>

  </main>

</template>


<script>
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";

export default {
  name: 'UserParticipate',
  props: ['initData','guestId'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }
    let generalContents = this.initData.lang.general
    let participateContents = this.initData.lang.user.participate
    let lastName = this.initData.user.lastName
    let firstName = this.initData.user.firstName
    let idNr = this.initData.user.idNr
    let telephone = this.initData.user.telephone
    let birthDate = this.initData.user.birthDate
    let gender = this.initData.user.gender
    let guests = this.initData.user.guests
    let guest = guests.find(g => g.idNr === this.guestId);
    let userFullName = null
    let userType = 1
    if(guest===undefined){
      if(idNr!==this.guestId){
        this.$router.push({name: 'userParticipation'})
      }
      userFullName = lastName+' '+firstName
    }else{
      if(guest.lastName!==null){
        userFullName = guest.lastName+' '+guest.firstName
      }else{
        userFullName = null
      }

      birthDate = guest.birthDate
      gender = guest.gender

      userType = 2

      telephone = null
    }

    let programsData = JSON.parse(JSON.stringify(this.initData.programs.data)).filter(function (el) {return el.preRegistration === '1' && el.userTypeAuth.includes(userType) && el.noteRequired === '0'})
    if(gender==1 || gender==2){
      programsData = JSON.parse(JSON.stringify(this.initData.programs.data)).filter(function (el) {return el.preRegistration === '1' && el.userTypeAuth.includes(userType) && el.noteRequired === '0' && el.genderRequired.includes(gender)})
    }


    return {
      userType,
      userFullName,
      generalContents,
      participateContents,
      programsData,
      fullName: null,
      fullNameError : null,
      birthDate,
      birthDateError : null,
      gender,
      genderError : null,
      programId : 0,
      programIdError : null,
      teamRequired : false,
      programTeams : [],
      teamId : 0,
      teamIdError : null,
      telephone,
      telephoneError : null,
      telephoneRequired : false,
      responsibility : false,
      responsibilityError : null
    }
  },
  watch:{
    gender(){
      let gender = this.gender
      let userType = this.userType
      this.programsData = JSON.parse(JSON.stringify(this.initData.programs.data)).filter(function (el) {return el.preRegistration === '1' && el.userTypeAuth.includes(userType) && el.noteRequired === '0' && el.genderRequired.includes(gender)})

    },
    programId(){
      if(this.programId!=='0') {
        if (this.programId !== 0) {
          let programId = this.programId
          let programData = JSON.parse(JSON.stringify(this.initData.programs.data)).filter(function (el) {return el.idNr === programId})[0]

          if (programData.telephoneRequired === '1') {
            this.telephoneRequired = true
          } else {
            this.telephoneRequired = false
          }

          if (programData.teamEntry === '1') {
            this.teamRequired = true
            this.programTeams = programData.teams
          } else {
            this.teamRequired = false
          }

        }
      }else{
        this.telephoneRequired = false
        this.teamRequired = false
      }

    },
    programsData(){

    }
  },
  methods:{
    submitHandle(){
      let validate = new Validate()

      //FULLNAME
      if(this.userFullName===null){
        this.fullNameError = validate.emptyField(this.fullName, this.participateContents.errors.fullNameRequired)
      }

      //BIRTHDATE
      this.birthDateError = validate.emptyField(this.birthDate, this.participateContents.errors.birthDateRequired)

      //GENDER
      this.genderError = validate.gender(this.gender, this.participateContents.errors.genderRequired)

      //PROGRAM ID
      this.programIdError = validate.isSelected(this.programId,this.participateContents.errors.programRequired)

      //TELEPHONE
      if(this.telephoneRequired){
        this.telephoneError = validate.telephone(this.telephone,this.participateContents.errors.telephoneRequired)
      }

      //TEAM ID
      if(this.teamRequired){
        this.teamIdError = validate.isSelected(this.teamId,this.participateContents.errors.teamRequired)
      }
      //RESPONSIBILITY
      this.responsibilityError = validate.checkboxChecked(this.responsibility,this.participateContents.errors.responsibilityRequired)

      if (this.fullNameError || this.birthDateError || this.genderError || this.programIdError || this.telephoneError || this.teamIdError || this.responsibilityError) {
        return false;
      }

      let formData = new FormData()
      formData.append('user_id_nr', this.guestId)
      formData.append('full_name', this.fullName)
      formData.append('birth_date', this.birthDate)
      formData.append('gender', this.gender)
      formData.append('program_id', this.programId)
      formData.append('telephone', this.telephone)
      formData.append('team_id', this.teamId)
      formData.append('responsibility', this.responsibility)

      this.initData.loader = true
      this.$axios.post('/v1/user/participate',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('redirect')===true){
              this.$router.push({name: responseData.data.redirect})
            }

            if(responseData.status===true) {
              let programs = responseData.data.programs
              if(programs!==null){
                this.initData.programs = programs
              }

              let user = responseData.data.user
              if(user!==null){
                this.initData.user.telephone = responseData.data.user.telephone
                this.initData.user.gender = responseData.data.user.gender
                this.initData.user.birthDate = responseData.data.user.birthDate
              }

              let guests = responseData.data.guests
              if(guests!==null){
                this.initData.user.guests = guests
              }

              this.$router.push({name: 'userParticipation'})
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }
}
</script>


<style scoped>

</style>