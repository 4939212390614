<template>

  <div class="modal fade" id="entryDeleteModal" tabindex="-1" aria-labelledby="entryDeleteModalLabel" aria-hidden="true" ref="modalRef">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="entryDeleteModalLabel">Megerősítés szükséges</h5>
        </div>
        <div class="modal-body">
          <p>
            <span class="fw-bold">Biztos, törlöd a nevezést?</span><br>
            A nevezési határidőig, ha még nem értük el a maximum létszámot, újra nevezhetsz.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" @click="agree" class="btn btn-danger" data-bs-dismiss="modal">Igen</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Mégse</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { Modal } from 'bootstrap'
import UserParticipation from '@/views/User/Participation'

export default {
  name: 'EntryDeleteAgree',
  props: ['initData'],
  data(){
    return{
      modalInstance : null,

    }
  },
  methods:{
    agree(){
      this.closeModal()

      UserParticipation.methods.entryDelete(this.$parent,this.initData.modals.entryAgree,true)
    },
    openModal(){
      this.modalInstance = new Modal(document.getElementById('entryDeleteModal'))
      this.modalInstance.show()
    },
    closeModal(){
      this.modalInstance = new Modal(document.getElementById('entryDeleteModal'))
      this.modalInstance.hide();
    }
  }
}
</script>
<style>

</style>