<template>

  <main>

    <section class="container pt-4 pb-5">

      <div class="row">

        <form @submit.prevent="handleSubmit" class="col-10 offset-1 col-lg-6 offset-lg-3">

          <h1 class="h3 text-center mb-4">
            {{registrationContents.title}}
          </h1>
          <p class="text-danger">
            Kizárólag aktív és tartósan távol lévő (pl. GYES-en lévő) kolléga regisztrálhat a rendezvényre.
          </p>

          <div class="mb-4">
            <label for="fullNameInput" class="form-label fw-bold mb-1 text-primary">
              {{registrationContents.fullNameLabel}}
            </label>
            <input type="text" class="form-control" :class="fullNameError ? 'is-invalid' : ''" v-model="fullName" id="fullNameInput" aria-describedby="fullNameHelp">
            <div v-if="fullNameError" class="small text-danger">
              {{ fullNameError }}
            </div>
            <div id="fullNameHelp" class="form-text">
              {{registrationContents.fullNameHelp}}
            </div>
          </div>

          <div class="mb-4">
            <h2 class="h6 fw-bold text-primary">Szervezeti egység, amiben dolgozol</h2>
            <div class="mb-1">
              <label for="divisionCategorySelect">
                Kategória
              </label>
              <select class="form-select mb-3" v-model="divisionCategory" id="divisionCategorySelect">
                <option value="0" selected>Kérjük válassz!</option>
                <option :value="division.id" v-for="division in divisionsData.filter(function (el) {return el.parentId===null})">{{division.name}}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="divisionSelect">
                Áruház / HO / Logisztika
              </label>
              <select class="form-select" v-model="division" id="divisionSelect">
                <option value="0" selected>Előbb válassz kategóriát!</option>
              </select>
              <div v-if="divisionError" class="small text-danger">
                {{divisionError}}
              </div>
              <div id="divisionSelectHelp" class="form-text">
                Add meg az áruházat / szervezeti egységet, ahol dolgozol.
              </div>
            </div>
          </div>

          <div class="mb-4 d-none">
            <div class="fw-bold mb-1 text-primary">
              Eljössz a rendezvényre?
            </div>
            <select class="form-select" v-model="eventApply">
              <option value="1">Megyek</option>
              <option value="0">Nem megyek</option>
            </select>
            <div v-if="eventApplyError" class="small text-danger">
              {{ eventApplyError }}
            </div>
          </div>

          <div class="mb-4" v-if="customSelect_1Required">
            <div class="fw-bold mb-1 text-primary">
              {{customSelect_1Required.label}}
            </div>
            <select class="form-select" v-model="customSelect_1">
              <option :value="option.id" v-for="option in customSelect_1Required.options">{{option.value}}</option>
            </select>
            <div v-if="customSelect_1Error" class="small text-danger">
              {{ customSelect_1Error }}
            </div>
            <div id="customSelect_1Help" class="form-text">
              A szállásról magadnak kell gondoskodnod.
            </div>
          </div>

          <div v-if="birthdayRequired===true || genderRequired===true" class="d-flex flex-wrap mb-1">
            <div v-if="birthdayRequired" class="mb-2 me-4">
              <label for="birthDateInput" class="form-label">{{registrationContents.dateLabel}}</label>
              <input type="date" class="form-control" :class="birthDateError ? 'is-invalid' : ''" v-model="birthDate" id="birthDateInput" aria-describedby="birthDate">
              <div v-if="birthDateError" class="small text-danger">
                {{ birthDateError }}
              </div>
            </div>
            <div v-if="genderRequired" class="mb-2">
              <label for="genderInput" class="form-label mb-2">{{registrationContents.genderLabel}}</label>
              <div class="d-flex">
                <div class="form-check me-3">
                  <input class="form-check-input" type="radio" name="genderInput" value="2" v-model="gender" id="genderFemale">
                  <label class="form-check-label" for="genderFemale">
                    {{registrationContents.genderFemaleLabel}}
                  </label>
                </div>
                <div class="form-check me-3">
                  <input class="form-check-input" type="radio" name="genderInput" value="1" v-model="gender" id="genderMale">
                  <label class="form-check-label" for="genderMale">
                    {{registrationContents.genderMaleLabel}}
                  </label>
                </div>
              </div>
            </div>
            <p class="form-text mb-4">{{registrationContents.genderHelp}}</p>
          </div>

<!--          <div class="mb-3">-->
<!--            <label for="companyInput" class="form-label">{{registrationContents.companyLabel}}</label>-->
<!--            <input type="text" class="form-control" id="companyInput" aria-describedby="Company">-->
<!--          </div>-->

<!--          <div class="mb-3">-->
<!--            <label for="divisionInput" class="form-label">{{registrationContents.division}}</label>-->
<!--            <input type="text" class="form-control" id="divisionInput" aria-describedby="division">-->
<!--          </div>-->

          <div class="mb-5" v-if="eventApply==1">
            <h2 class="h6 fw-bold mb-1 text-primary">
              Kísérő közvetlen családtag(ok)
            </h2>
            <div class="row justify-content-around mb-2">
              <div class="col-6">
                <label for="familyMemberUnderAge" class="form-label mb-0">14 év alatti (fő)</label>
                <input type="number" class="form-control text-center" min="0" max="4" v-model="ageUnder" id="familyMemberUnderAge">
                <!--<div class="small text-danger">
                  Error
                </div>-->
              </div>
              <div class="col-6">
                <label for="familyMemberAboveAge" class="form-label mb-0">14 év feletti (fő)</label>
                <input type="number" class="form-control text-center" min="0" max="4" v-model="ageAbove" id="familyMemberAboveAge">
                <!--<div class="small text-danger">
                  Error
                </div>-->
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-primary form-control">
            {{registrationContents.submitBtn}}
          </button>

        </form>

      </div>

    </section>

  </main>

</template>


<script>
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";
import TomSelect from "tom-select";

export default {
  name: 'UserRegistration',
  props: ['initData'],
  data() {
    let registrationContents = this.initData.lang.user.registration
    let userRegistration = this.initData.user.registration
    if (userRegistration===false) {
      this.initData.alerts.push({msg: registrationContents.errors.userRegistrationRequired, type: 'error'})
      this.$router.push({name: 'login'})
    }

    let divisionsData = this.initData.divisions

    let registrationSetup = this.initData.event.registrationSetup
    let genderRequired = registrationSetup.genderRequired
    let birthdayRequired = registrationSetup.birthdayRequired
    let customSelect_1Required = registrationSetup.customSelect_1Required

    return {
      registrationContents,
      divisionsData,
      divisionsSubData:[],
      eventApply : 1,
      eventApplyError : null,
      fullName : null,
      fullNameError : null,
      birthDate : null,
      birthDateError : null,
      gender : 0,
      genderRequired,
      birthdayRequired,
      ageUnder : 0,
      ageAbove: 0,
      divisionCategory: 0,
      division: 0,
      divisionError: null,
      tsDivision : null,
      customSelect_1Required,
      customSelect_1 : 0,
      customSelect_1Error: null
    }
  },
  watch:{
    divisionCategory(){
      let divisionCategory = this.divisionCategory
      if(divisionCategory!=0){
        let divisionFilter = this.divisionsData.filter(function (el) {return el.parentId===divisionCategory})

        let divisions = []
        for(let i=0; i < divisionFilter.length; i++){
          divisions.push({value:divisionFilter[i].id, text:divisionFilter[i].name})
        }

        this.tsDivision.clear(true);
        this.tsDivision.clearOptions();
        this.tsDivision.addOptions(divisions);
        this.tsDivision.refreshOptions();
      }else if(divisionCategory==0){
        this.division = 0
        this.tsDivision.clear(true);
        this.tsDivision.clearOptions();
        this.tsDivision.addOptions([{value:'0',text:'Előbb válassz kategóriát!'}]);
        this.tsDivision.refreshOptions();
      }
    }
  },
  mounted() {
    let tsSettings = {
      maxOptions : null,
      render: {
        no_results: function(data,escape){
          return '<div class="no-results">Nincs találat</div>';
        }
      }
    };
    this.tsDivision = new TomSelect('#divisionSelect',tsSettings);
  },
  methods: {
    handleSubmit() {
      let validate = new Validate()

      this.fullNameError = validate.emptyField(this.fullName, this.registrationContents.errors.fullNameRequired)

      //DIVISION
      this.divisionError = validate.isSelected(this.division,this.registrationContents.errors.divisionRequired)


      //BIRTHDATE
      if(this.birthdayRequired){

      }

      //GENDER
      if(this.genderRequired){

      }

      //AGE UNDER AND ABOVE

      if (this.fullNameError || this.divisionError) {
        return false;
      }

      let formData = new FormData()
      formData.append('event_apply', this.eventApply)
      formData.append('full_name', this.fullName)
      formData.append('birth_date', this.birthDate)
      formData.append('gender', this.gender)
      formData.append('age_under', this.ageUnder)
      formData.append('age_above', this.ageAbove)
      formData.append('division', this.division)
      formData.append('custom_select_1', this.customSelect_1)

      this.initData.loader = true
      this.$axios.post('/v1/user/registration',formData)
        .then((response) => {
          let responseData =  new Response().handle(response)
          this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

          if(responseData.status===true) {
            this.initData.user.loggedIn = true
            this.initData.programs = responseData.data.programs
            this.initData.voluntaryActs = responseData.data.voluntaryActs
            this.initData.info = responseData.data.info
            this.initData.info_2 = responseData.data.info_2
            this.initData.user.qrCode = responseData.data.qrCode
            this.initData.user.idNr = responseData.data.idNr
            this.initData.user.division = responseData.data.division
            this.initData.user.eventApply = responseData.data.eventApply
            this.initData.user.firstName = responseData.data.firstName
            this.initData.user.lastName = responseData.data.lastName
            this.initData.user.permission = responseData.data.permission
            this.initData.user.birthDate = responseData.data.birthDate
            this.initData.user.gender = responseData.data.gender
            this.initData.user.guests = responseData.data.guests
            this.initData.user.customSelect_1 = responseData.data.customSelect_1
            this.$router.push({name: 'agenda'})
          }
        })
        .catch(error => {
          console.log(error)
          let responseData =  new Response()
          responseData.errorHandle(error.response)
          this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
        })
        .then(() => {
          this.initData.loader = false
        });
    }
  }
}
</script>


<style>

</style>