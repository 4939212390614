<template>

  <main>

    <section class="container pt-4">
      <div class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <!--
        <div class="d-flex justify-content-center align-items-center bg-info mb-2">
          <div v-html="qrCode" class="qr-code"></div>
        </div>
        -->

        <h1 class="h3 mb-4 text-center">{{fullName}}</h1>
        <p class="text-danger"> Kizárólag aktív kolléga / tartósan távol lévő (pl. GYES-en lévő) kolléga és közvetlen családtagja vehet részt a rendezvényen. Volt munkatársat ne hívj magaddal (kivéve, ha közvetlen családtagod)!</p>

        <!--
        <p>
          <strong>Azonosító:</strong><br>
          {{eventId}}-{{idNr}}
        </p>
        <p>
          <th scope="row">Született:</th>
          <td>{{birthDate}}</td>
        </p>
        <p>
          <th scope="row">Nem:</th>
          <td v-if="gender===1">Nő</td>
          <td v-else>Férfi</td>
        </p>
        <p>
          <th scope="row">Cég:</th>
          <td>-</td>
        </p>
        -->

        <form @submit.prevent="handleSubmit(this,false)" class="mb-4">

          <div class="mb-4 d-none">
            <label class="fw-bold mb-1 text-primary" for="eventApplySelect">Eljössz a rendezvényre?</label>
            <select class="form-select mb-3" v-model="eventApply" id="eventApplySelect" aria-label="eventApplyHelp">
              <option value="1" selected>Megyek</option>
              <option value="0">Nem megyek</option>
            </select>
            <div v-if="eventApplyError" class="small text-danger">
              {{ eventApplyError }}
            </div>
          </div>

          <div class="mb-4">
            <h2 class="h6 fw-bold mb-1 text-primary">Szervezeti egység, amiben dolgozol</h2>
            <div class="mb-1">
              <label for="divisionCategorySelect">
                Kategória
              </label>
              <select id="divisionCategorySelect" class="form-select mb-3" v-model="divisionCategory" aria-label="divisionCategory">
                <option value="0" selected>Kérjük válassz!</option>
                <option :value="division.id" v-for="division in divisionsData.filter(function (el) {return el.parentId===null})">{{division.name}}</option>
              </select>
            </div>
            <div>
              <label for="divisionSelect">
                Áruház / HO / Logisztika
              </label>
              <select id="divisionSelect" class="form-select" v-model="division">
                <option value="0" selected>Előbb válassz kategóriát!</option>
              </select>
              <div v-if="divisionError" class="small text-danger">
                Kérjük, válaszd ki azt az áruházat / szervezeti egységet, ahol dolgozol!
              </div>
            </div>
          </div>

          <div class="mb-5" v-if="customSelect_1Required">
            <label for="eventSelect_1" class="fw-bold mb-1 text-primary">
              {{customSelect_1Data.label}}
            </label>
            <select id="eventSelect_1" class="form-select" v-model="customSelect_1">
              <option :value="option.id" v-for="option in customSelect_1Data.options">{{option.value}}</option>
            </select>
            <div v-if="customSelect_1Error" class="small text-danger">
              {{ customSelect_1Error }}
            </div>
          </div>

          <div class="text-center mb-3">
            <button class="btn btn-primary form-control mb-2">
              Mentés
            </button>
          </div>

          <div class="text-center mb-5">
            <router-link :to="{name:'userVoluntary'}">
              <button class="btn btn-sm btn-outline-secondary form-control mb-2">
                Önkéntes munkák
              </button>
            </router-link>
          </div>

          <div class="mb-3">
            <h2 class="h6 fw-bold">Kísérő közvetlen családtag(ok)</h2>
            <h3 class="h6">
              <span class="text-decoration-underline">14 év alatt</span>: {{ageUnderNumber}} fő
            </h3>
            <ol class="family-member-list family-member-list--under-fourteen">
              <li v-for="guest in guests.filter(function (el) {return el.ageUnder==1 && el.isDeleted===false})">családtag: <span v-if="guest.lastName!==null">{{guest.lastName}} {{guest.firstName}}</span><span v-else>Anonim</span> <i class="bi bi-x-circle ms-1 text-danger" @click="guestDelete(this,guest.idNr,false)"></i></li>
            </ol>
            <h3 class="h6">
              <span class="text-decoration-underline">14 év felett</span>: {{ageAboveNumber}} fő
            </h3>
            <ol class="family-member-list family-member-list--over-fourteen">
              <li v-for="guest in guests.filter(function (el) {return el.ageUnder==0 && el.isDeleted===false})">családtag: <span v-if="guest.lastName!==null">{{guest.lastName}} {{guest.firstName}}</span><span v-else>Anonim</span> <i class="bi bi-x-circle ms-1 text-danger" @click="guestDelete(this,guest.idNr,false)"></i></li>
            </ol>
          </div>
          <div class="text-center">
            <router-link :to="{name:'userNewGuest'}">
              <button class="btn btn-sm btn-outline-secondary form-control mb-2">
                Családtag hozzáadása
              </button>
            </router-link>
          </div>

        </form>
      </div>
    </section>

  </main>
  <EventApplyAgree></EventApplyAgree>
  <GuestDeleteAgree :initData = initData></GuestDeleteAgree>

</template>


<script>
import Header from '@/components/Header'
import {Validate} from '@/classes/validate'
import {Response} from '@/classes/response'
import TomSelect from "tom-select"
import EventApplyAgree from '@/views/Modals/EventApplyAgree'
import GuestDeleteAgree from '@/views/Modals/GuestDeleteAgree'

export default {
  name: 'UserAccount',
  props: ['initData'],
  components: {
    Header, EventApplyAgree, GuestDeleteAgree
  },
  data(){
    let eventId = this.initData.event.id
    let loggedIn = this.initData.user.loggedIn
    let permission = this.initData.user.permission
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }

    let serverDateTime = this.initData.user.serverDateTime
    let accountDisplay = this.initData.event.pageDisplay.profile
    if(serverDateTime<accountDisplay && permission!==90){
      this.$router.push({name: 'info'})
    }

    let accountSetup = this.initData.event.accountSetup
    let customSelect_1Required = accountSetup.customSelect_1Required

    let registrationSetup = this.initData.event.registrationSetup
    let customSelect_1Data = registrationSetup.customSelect_1Required

    let generalContents = this.initData.lang.general
    let accountContents = this.initData.lang.user.account

    let guests = this.initData.user.guests

    let idNr = this.initData.user.idNr
    let division = this.initData.user.division
    let qrCode = this.initData.user.qrCode
    let lastName = this.initData.user.lastName
    let firstName = this.initData.user.firstName
    let birthDate = this.initData.user.birthDate
    let gender = this.initData.user.gender
    let eventApply = this.initData.user.eventApply
    let customSelect_1 = this.initData.user.customSelect_1

    let divisionsData = this.initData.divisions
    let userDivision = divisionsData.filter(obj => { return obj.id === division })
    let divisionCategory = userDivision[0].parentId

    return{
      Header,
      generalContents,
      accountContents,
      eventId,
      qrCode,
      idNr,
      division,
      divisionError: null,
      divisionCategory,
      divisionsData,
      birthDate,
      gender,
      eventApply,
      eventApplyError : null,
      fullName : lastName+' '+firstName,
      guests,
      tsDivision : null,
      customSelect_1Required,
      customSelect_1Data,
      customSelect_1,
      customSelect_1Error: null,
      EventApplyAgree,
      GuestDeleteAgree
    }
  },computed:{
    ageUnderNumber(){
      return this.guests.filter(function (el) {return el.ageUnder==1 && el.isDeleted===false}).length
    },
    ageAboveNumber(){
      return this.guests.filter(function (el) {return el.ageUnder==0 && el.isDeleted===false}).length
    }
  },
  watch:{
    divisionCategory(){
      let divisionCategory = this.divisionCategory
      if(divisionCategory!=0){
        let divisionFilter = this.divisionsData.filter(function (el) {return el.parentId===divisionCategory})

        let divisions = []
        for(let i=0; i < divisionFilter.length; i++){
          divisions.push({value:divisionFilter[i].id, text:divisionFilter[i].name})
        }

        this.division = 0
        this.tsDivision.clear(true);
        this.tsDivision.clearOptions();
        this.tsDivision.addOptions(divisions);
        this.tsDivision.refreshOptions();
      }else if(divisionCategory==0){
        this.division = 0
        this.tsDivision.clear(true);
        this.tsDivision.clearOptions();
        this.tsDivision.addOptions([{value:'0',text:'Előbb válassz kategóriát!'}]);
        this.tsDivision.refreshOptions();
      }
    }
  },
  mounted() {
    let tsSettings = {
      maxOptions : null,
      render: {
        no_results: function(data,escape){
          return '<div class="no-results">Nincs találat</div>';
        }
      }
    };
    this.tsDivision = new TomSelect('#divisionSelect',tsSettings);

    let divisionCategory = this.divisionCategory
    let divisionFilter = this.divisionsData.filter(function (el) {return el.parentId===divisionCategory})

    let divisions = []
    for(let i=0; i < divisionFilter.length; i++){
      divisions.push({value:divisionFilter[i].id, text:divisionFilter[i].name})
    }

    this.tsDivision.clear();
    this.tsDivision.clearOptions();
    this.tsDivision.addOptions(divisions,false);
    this.tsDivision.setValue([this.division]);
    this.tsDivision.close();
  },
  methods:{
    handleSubmit(e,agreed) {
      let validate = new Validate()

      e.divisionError = validate.isSelected(e.division,e.accountContents.errors.divisionRequired)

      if (e.divisionError) {
        return false
      }

      if(e.eventApply==0 && agreed===false){
        e.EventApplyAgree.methods.openModal()
        return false
      }

      let formData = new URLSearchParams()
      formData.append('event_apply', e.eventApply)
      formData.append('division', e.division)
      formData.append('custom_select_1', e.customSelect_1)

      e.initData.loader = true
      e.$axios.put('/v1/user/',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('redirect')===true){
              e.$router.push({name: responseData.data.redirect})
            }

            if(responseData.status===true) {
              e.initData.user.division = responseData.data.division
              e.initData.user.eventApply = responseData.data.eventApply
              e.initData.user.customSelect_1 = responseData.data.customSelect_1
              if(responseData.data.eventApply==0){
                e.guests.forEach(deleteGuest);
                function deleteGuest(guest) {
                  guest.isDeleted = true
                }

                e.initData.programs.entries = []
              }
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });
    },
    guestDelete(e,idNr,agreed) {
      if(agreed===false){
        e.initData.modals.guestAgree = idNr
        e.GuestDeleteAgree.methods.openModal()
        return false
      }

      e.initData.loader = true
      e.$axios.delete('/v1/user/guest/'+idNr+'/')
          .then((response) => {
            let responseData =  new Response().handle(response)
            console.log(responseData)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.status===true) {
              let idNr =  responseData.data.idNr
              let guest = e.guests.filter(obj => { return obj.idNr === idNr })
              guest[0].isDeleted = true
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            e.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            e.initData.loader = false
          });

    }
  }
}
</script>


<style scoped>
i.bi.bi-x-circle{
  cursor: pointer;
}
</style>