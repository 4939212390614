<template>

  <main>

    <section class="container py-3">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <h1 class="h3 text-center mb-4">Mutasd fel a QR-kódot a jegy(ek) beváltásához!</h1>
        <div class="d-flex justify-content-center align-items-center bg-info mb-2">
          <img src="/img/qr-code-csapatnap.svg" alt="QR Code" class="qr-code">
        </div>
        <p class="mb-5 text-center small">Kiválasztott jegyek alapján egyedileg generált QR-kód.</p>
        <router-link :to="{name:'userTickets'}"><button class="btn btn-outline-primary form-control"><i class="bi bi-arrow-left me-2"></i>Vissza</button></router-link>
      </div>
    </section>

  </main>

</template>


<script>
export default {
  name: 'UserCustomQrCode',
  components: {

  }
}
</script>


<style>

</style>