<template>

  <main>

    <section class="container pt-4">

      <div class="row">

        <div class="col-12 col-md-6 offset-md-3">
          <h1 class="h3 text-center mb-3">Csapat módosítás</h1>
        </div>

        <form @submit.prevent="handleSubmit" class="col-12 col-md-6 offset-md-3">

          <div class="mb-3">
            Program: <strong>{{program.name}}</strong><br>
            Csapatnév: <strong>{{team.teamName}}</strong><br>
            Csapatkapitány: <strong>{{lastName}} {{firstName}}</strong><br>
            Csapatkapitány telefonszáma: <strong>{{telephone}}</strong>
          </div>

          <div class="mb-5">
            <label for="teamNoteTextarea" class="form-label fw-bold text-primary">Csapattagok (Saját magadat ne felejtsd ki a felsorolásból!)</label>
            <textarea id="teamNoteTextarea" v-model="note" :class="noteError ? 'is-invalid' : ''" class="form-control" aria-label="Team note" rows="11"
            :placeholder="'Csapattag neve - Áruház, \n' +
            'Csapattag neve - Áruház\n' +
            '... \n' +
            'Kérünk, soronként egy csapattagot adj meg.'"></textarea>
            <div v-if="noteError" class="small text-danger">
              {{ noteError }}
            </div>
            <div id="teamNameHelp" class="form-text">
              Add meg a <span class="fw-bold text-secondary">csapattagok nevét</span>, és hogy melyik <span class="fw-bold text-secondary">szervezeti egységben (áruházban)</span> dolgoznak!
              Ha munkatárs családtagja a csapattag, kérjük, írd a neve mellé, hogy <span class="fw-bold text-secondary">családtag</span>.
            </div>
          </div>

          <div class="d-flex">
            <router-link :to="{name:'userParticipation'}" class="mx-1 w-100">
              <button class="btn btn-outline-primary form-control">Vissza</button>
            </router-link>
            <button type="submit" class="btn btn-primary form-control">Módosítom</button>
          </div>

        </form>

      </div>

    </section>

  </main>

</template>


<script>
import {Validate} from "@/classes/validate";
import {Response} from "@/classes/response";

export default {
  name: 'UserNewTeam',
  props: ['initData','entryId'],
  data(){
    let loggedIn = this.initData.user.loggedIn
    if(loggedIn===false){
      this.$router.push({name: 'login'})
    }

    let modifyTeamContents = this.initData.lang.user.modifyTeam

    let lastName = this.initData.user.lastName
    let firstName = this.initData.user.firstName
    let telephone = this.initData.user.telephone

    let programs = this.initData.programs
    let entryId = this.entryId
    let entry = programs.entries.filter(function (el) {return el.entryId==entryId})[0]
    let programIdNr = entry.programIdNr
    let teamId = entry.teamId
    let program = programs.data.filter(function (el) {return el.idNr==programIdNr})[0]
    let team = program.teams.filter(function (el) {return el.teamId==teamId})[0]
    let note = team.note

    return{
      lastName,
      firstName,
      telephone,
      modifyTeamContents,
      program,
      programs,
      team,
      note,
      noteError : null

    }
  },
  methods: {
    handleSubmit() {
      let validate = new Validate()

      this.noteError = validate.emptyField(this.note,this.modifyTeamContents.errors.noteRequired)

      if (this.noteError) {
        return false;
      }

      let formData = new URLSearchParams()
      formData.append('note', this.note)

      this.initData.loader = true
      this.$axios.put('/v1/user/team/'+this.entryId+'/',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})

            if(responseData.data!==null && responseData.data.hasOwnProperty('redirect')===true){
              this.$router.push({name: responseData.data.redirect})
            }

            if(responseData.status===true) {
              let entryId = this.entryId
              let entry = this.programs.entries.filter(function (el) {return el.entryId==entryId})[0]
              let programIdNr = entry.programIdNr
              let teamId = entry.teamId
              let program = this.programs.data.filter(function (el) {return el.idNr==programIdNr})[0]
              program.teams.filter(function (el) {return el.teamId==teamId})[0].note = responseData.data.note
              //this.$router.push({name: 'userParticipation'})
            }
          })
          .catch(error => {
            console.log(error)
            let responseData =  new Response()
            responseData.errorHandle(error.response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
          })
          .then(() => {
            this.initData.loader = false
          });
    }
  }
}
</script>


<style>

</style>